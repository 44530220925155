<template>
  <!-- <el-card> -->
  <div class="common-layout">
    <!-- <div class="bac"> -->
    <!-- 站点管理 -->
    <div class="icon">【 <span class="titleOne">基本信息</span> 】</div>
    <div class="formBac">
      <el-scrollbar height="384px" width="300px">
        <el-form
          ref="formRef1"
          :model="form"
          :rules="rules1"
          label-width="auto"
          style="
            width: 1264px;
            height: 352px;
            margin-left: 3.19%;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
          "
        >
          <!-- <div style=""> -->
          <el-form-item
            :label="inputItems[0].label"
            :prop="inputItems[0].prop"
            style="
              width: 392px;
              height: 40px;
              display: flex;
              justify-content: space-between;
            "
          >
            <el-input
              v-model="form.companyName"
              style="width: 280px; height: 40px"
            />
          </el-form-item>
          <el-form-item
            :label="inputItems[1].label"
            :prop="inputItems[1].prop"
            style="width: 390px; height: 40px"
          >
            <el-input
              v-model="form.recordNumber"
              style="width: 280px; height: 40px"
            />
          </el-form-item>
          <el-form-item
            :label="inputItems[2].label"
            :prop="inputItems[2].prop"
            style="width: 390px; height: 40px"
          >
            <el-input
              v-model="form.nationalCSHotline"
              style="width: 280px; height: 40px"
            />
          </el-form-item>
          <el-form-item
            :label="inputItems[3].label"
            :prop="inputItems[3].prop"
            style="width: 392px; height: 40px"
          >
            <el-input
              v-model="form.companyAddress"
              style="width: 280px; height: 40px"
            />
            <!-- 280px -->
          </el-form-item>
          <el-form-item
            :label="inputItems[4].label"
            :prop="inputItems[4].prop"
            style="width: 390px; height: 40px; margin-right: 435px"
          >
            <el-input
              v-model="form.companyEmail"
              style="width: 280px; height: 40px"
            />
          </el-form-item>
          <el-form-item
            :label="inputItems[5].label"
            :prop="inputItems[5].prop"
            style="width: 100%; height: 150px; margin-right: 817px"
          >
            <el-upload
              v-model="form.webLogo"
              :list-type="inputItems[5].filetype"
              :auto-upload="inputItems[5].upload"
              :on-change="handleChange1"
              multiple
              :limit="inputItems[5].limit"
              :before-upload="beforeAvatarUpload1"
              :on-exceed="handleOnExceed1"
              accept=".jpg, .png"
              :file-list="inputItems[5].fileList"
              :http-request="handleRequest1"
              :on-preview="handlePreview1"
              :on-remove="handleRemove1"
              style="margin-top: -40px"
            >
              <!-- <div style="font-size: 45px; color: #c1cfe5">+</div> -->
              <!-- + -->
              <el-icon><Plus /></el-icon>
            </el-upload>
            <!-- 图片放大预览 -->
            <el-dialog class="imgPreviewDia" v-model="webLogoDialogVisible">
              <img w-full :src="webLogoDialogImageUrl" alt="Preview Image" />
            </el-dialog>
          </el-form-item>
          <el-form-item
            :label="inputItems[6].label"
            :prop="inputItems[6].prop"
            style="width: 100%; height: 150px; margin-top: -50px"
          >
            <!--  v-model:file-list="fileList1" :file-list="inputItems[6].fileList" -->
            <el-upload
              v-model="form.bottomImage"
              :auto-upload="inputItems[6].upload"
              :on-change="handleChange2"
              multiple
              :limit="inputItems[6].limit"
              :before-upload="beforeAvatarUpload2"
              :on-exceed="handleOnExceed2"
              accept=".jpg, .png"
              :file-list="inputItems[6].fileList"
              :list-type="inputItems[6].filetype"
              :http-request="handleRequest2"
              :on-preview="handlePreview2"
              :on-remove="handleRemove2"
              style="margin-top: -30px"
            >
              <el-icon><Plus /></el-icon>
              <!-- <div style="font-size: 45px; color: #c1cfe5">+</div> -->
            </el-upload>
            <el-dialog class="imgPreviewDia" v-model="dialogVisible">
              <img w-full :src="dialogImageUrl" alt="Preview Image" />
            </el-dialog>
          </el-form-item>
          <!-- </div> -->
        </el-form>
      </el-scrollbar>
    </div>
    <!-- 友情链接 -->
    <div class="content_twoBac">
      <div class="icon">【 <span class="titleOne">友情链接</span> 】</div>
      <div class="content_button">
        <el-button plain @click="addItems">
          <span class="addWord">添加</span>
        </el-button>
      </div>
      <!-- 添加/编辑表单 @close="handleClose" -->
      <el-dialog
        :before-close="handleClose"
        v-model="dialogFormVisible"
        :title="dialogTitle"
        class="formStyle"
        style="
          width: 360px;
          height: 340px;
          background: #ffffff;
          box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.12);
          border-radius: 6px 6px 6px 6px;
          border: 1px solid #c5d0e0;
        "
      >
        <el-form
          ref="formRef"
          :model="form1"
          :rules="rules2"
          size="mini"
          style="margin-top: 56px"
        >
          <el-form-item
            label="网站名称"
            :prop="form1Items[0].prop"
            :label-width="formLabelWidth"
            style="width: 312px; height: 32px"
          >
            <el-input
              v-model="form1.webName"
              autocomplete="off"
              required
              style="width: 240px; height: 32px"
            />
          </el-form-item>
          <el-form-item
            label="网站链接"
            :prop="form1Items[1].prop"
            :label-width="formLabelWidth"
            style="width: 312px; height: 32px"
          >
            <el-input
              v-model="form1.webLink"
              autocomplete="off"
              required
              style="width: 240px; height: 32px"
            />
          </el-form-item>
          <el-form-item
            label="排序"
            :prop="form1Items[2].prop"
            :label-width="formLabelWidth"
            style="width: 312px; height: 32px"
          >
            <el-input
              v-model="form1.sort"
              autocomplete="off"
              required
              style="width: 240px; height: 32px"
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="dialog-footer">
            <el-button class="cancelBtn" @click="cancel">
              <span class="cancelWord">取消</span></el-button
            >
            <el-button class="certainBtn" @click="addToTable">
              <span class="addWord">确定</span>
            </el-button>
          </div>
        </template>
      </el-dialog>
      <div class="elmTableBac">
        <el-scrollbar height="88%">
          <elmTable
            :table-data="tableData"
            :table-cols="tableCols"
            :total="totalData"
            :sortStuat="sortStuat"
            :paginationStatu="paginationStatu"
            v-loading="loading"
            @onCurrentPageChange="onCurrentPageChange"
            @onPageSizeChange="onPageSizeChange"
          />
        </el-scrollbar>
      </div>
    </div>
    <div class="saveButton">
      <el-button plain @click="handleSubmit" style="margin-left: 14px">
        <span class="addWord">保存</span>
      </el-button>
    </div>
    <!-- </div> -->
  </div>
  <!-- </el-card> -->
</template>

<script setup>
import elmTable from "@/components/elmTable.vue";
import { reactive, ref, onMounted } from "vue";
import { Plus } from "@element-plus/icons-vue";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  uploadFilesApi, // 上传图片
  addSiteBasicApi, // 插入站点信息
  getSiteBasicApi, // 获取站点信息
  // getFriendlyLinkApi, // 分页查询友情链接
  addFriendlyLinkApi, //添加一行
  editFriendlyLinkApi, // 编辑一行
  deleteFriendlyLinkApi, // 删除一行
} from "@/api/siteAdministrationApi";

// 表格加载动画
const loading = ref(false);

const paginationStatu = ref(false);

// 表单
const inputItems = reactive([
  { prop: "companyName", label: "公司名称" },
  { prop: "recordNumber", label: "备案号" },
  { prop: "nationalCSHotline", label: "全国客服热线" },
  { prop: "companyAddress", label: "公司地址" },
  { prop: "companyEmail", label: "公司Email" },
  {
    prop: "webLogo",
    label: "公司logo",
    filetype: "picture-card",
    upload: false,
    limit: 1,
    fileList: [], // 文件列表
  },
  {
    prop: "bottomImage",
    label: "底部图片",
    filetype: "picture-card",
    upload: false,
    limit: 3,
    fileList: [], // 文件列表
  },
]);

let form = reactive({
  // 表单
  id: 1,
  companyName: "", // 公司名称
  recordNumber: "", // 备案号
  nationalCSHotline: "", // 全国服务热线
  companyAddress: "", // 公司地址
  companyEmail: "", // 公司Email
  webLogo: "", //公司logo
  bottomImage: [], // 底部图片
});

//基本信息表单 校验规则
const rules1 = reactive({
  companyName: [
    { required: true, message: "请输入公司名称", trigger: "blur" },
    { min: 4, max: 18, message: "公司名称字符为4~18之间", trigger: "blur" },
  ],
  recordNumber: [{ required: true, message: "请输入备案号", trigger: "blur" }],
  nationalCSHotline: [
    { required: true, message: "请输入全国服务热线", trigger: "blur" },
  ],
  companyAddress: [
    { required: true, message: "请输入公司地址", trigger: "blur" },
  ],
  companyEmail: [
    { required: true, message: "请输入公司Email", trigger: "blur" },
    { min: 6, max: 18, message: "邮箱字符为6~18之间", trigger: "blur" },
  ],
  webLogo: [{ required: true, message: "请输入公司logo", trigger: "blur" }],
  bottomImage: [{ required: true, message: "请输入底部图片", trigger: "blur" }],
});

const formRef1 = ref();

// 公司logo
// const fileList1 = ref([]);
const dialogImageUrl = ref(""); // 预览大图地址
const dialogVisible = ref(false); // 蒙版开关
// 超出范围触发的函数
const handleOnExceed1 = () => {
  ElMessage.error("只能上传1张图片");
};
// 文件上传前触发的函数
const beforeAvatarUpload1 = (rawFile) => {
  if (rawFile.size > 2 * 1024 * 1024) {
    ElMessage.error("文件最大 2MB!");
    return false;
  }
  return true;
};

const handleChange1 = (file, fileLists) => {
  inputItems[5].fileList = fileLists;
  form.webLogo = fileLists[0].url;
};

// 自定义上传
const handleRequest1 = async (data) => {
  // 用表单来初始化
  let fileData = new FormData();
  //  append() 添加一个新值到FormData对象内的一个已存在的键中，如果键不存在则会添加该键
  // FormData.set和append()区别：如果指定的键已存在，FormData.set会用新值覆盖已有的值，而append()会把新值添加到已有值集合后面

  // formData.append(name, value); name：字段名称（key），value:表单的值
  // 添加一个名为"files"的键值对
  fileData.append("files", data);
  const datas = await uploadFilesApi(fileData);
  // 将图片转化为地址存入
  return `${datas.data}`;
};

// 文件删除
const handleRemove1 = () => {
  // uploadFile, uploadFiles
  form.webLogo = "";
};

// 打开蒙版 预览大图
const handlePreview1 = (uploadFile) => {
  dialogImageUrl.value = uploadFile.url;
  dialogVisible.value = true;
};

// 底部图片 三张
const webLogoDialogImageUrl = ref(""); // 预览大图地址
const webLogoDialogVisible = ref(false); // 蒙版开关
// 超出范围触发的函数
const handleOnExceed2 = () => {
  ElMessage.error("只能上传3张图片");
};
// 文件上传前触发的函数
const beforeAvatarUpload2 = (rawFile) => {
  if (rawFile.size > 2 * 1024 * 1024) {
    ElMessage.error("文件最大 2MB!");
    return false;
  }
  return true;
};

const handleChange2 = (file, fileLists) => {
  inputItems[6].fileList = fileLists;
};

// 自定义上传
const handleRequest2 = async (data) => {
  let imgData = [];
  for (let items of data) {
    if (items.raw) {
      let fileData = new FormData();
      fileData.append("files", items.raw);
      const datas = await uploadFilesApi(fileData);
      imgData.push({
        img: datas.data,
      });
    } else {
      imgData.push(items);
    }
  }
  return imgData;
};

// 文件删除 uploadFile, uploadFiles
const handleRemove2 = () => {
  // console.log(uploadFile, uploadFiles);
};

// 打开蒙版 预览大图
const handlePreview2 = (uploadFile) => {
  webLogoDialogImageUrl.value = uploadFile.url;
  webLogoDialogVisible.value = true;
};

// 保存 表单提交按钮
const handleSubmit = async () => {
  formRef1.value
    .validate()
    .then(async () => {
      let postData = { ...form };
      const prefixToRemove = `${window.apiConfig.VUE_APP_GATHER_URL.value}/`;
      // 公司logo
      let webLogo = "";
      if (inputItems[5].fileList[0].raw) {
        webLogo = await handleRequest1(inputItems[5].fileList[0].raw);
      } else {
        webLogo = form.webLogo;
      }
      form.bottomImage = [];
      // 底部图片
      const imgData = await handleRequest2(inputItems[6].fileList);
      postData.bottomImage = [...imgData];

      let paths = [];

      for (const item of postData.bottomImage) {
        let path;
        if ("img" in item) {
          // 如果是第一种结构，提取img
          path = item.img;
        } else if ("url" in item) {
          // 如果是后两种结构，提取url
          path = item.url;
        }
        if (path && path.startsWith(prefixToRemove)) {
          path = path.replace(prefixToRemove, "");
        } else {
          // console.log(prefixToRemove, path, "没有触发");
        }
        if (path) {
          // 确保路径存在才添加到新数组
          paths.push(path);
        }
      }
      if (Array.isArray(paths)) {
        postData.bottomImage = paths.join(",");
      }
      postData.webLogo = webLogo;
      postData.id = form.id;
      // 表单数据
      const paramData = JSON.stringify(postData);
      await addSiteBasicApi(paramData);
      getList();
      ElMessage({
        type: "success",
        message: "保存成功",
      });
    })
    .catch((err) => {
      console.error("表单验证失败:", err);
    });
};

// 弹窗默认关闭
const dialogFormVisible = ref(false);
// 弹框的title值
const dialogTitle = ref("添加");

const form1Items = reactive([
  { label: "网站名称", prop: "webName" },
  { label: "网站链接", prop: "webLink" },
  { label: "排序", prop: "sort" },
]);

let form1 = reactive({
  // 添加弹框
  id: 0,
  webName: "", // 网站名称
  webLink: "", // 网站链接
  sort: null, // 排序
});

// 友情链接 添加/编辑表单 校验规则
const rules2 = reactive({
  webName: [{ required: true, message: "请输入网站名称", trigger: "blur" }],
  webLink: [{ required: true, message: "请输入网站链接", trigger: "blur" }],
  sort: [
    { required: true, message: "请输入排序", trigger: "blur" },
    // { type: "number", message: "排序是一个数", trigger: "blur" },
  ],
});

// 添加 友情链接数据
const addItems = () => {
  modalType.value = 0;
  dialogTitle.value = "添加";
  dialogFormVisible.value = true;
};

//添加弹框 中确认按钮
const formRef = ref();

let modalType = ref(0); // 0是新增 1是编辑

// 编辑 友情链接数据
const editData = async (row) => {
  // 将表格中的数据回显到表单上
  for (let key in row) {
    if (Object.prototype.hasOwnProperty.call(form1, key)) {
      form1[key] = row[key];
    }
  }
  modalType.value = 1;
  dialogTitle.value = "编辑";
  dialogFormVisible.value = true;
};
// 弹窗 取消
const handleClose = () => {
  form1 = reactive({
    // 添加弹框
    id: 0,
    webName: "", // 网站名称
    webLink: "", // 网站链接
    sort: null, // 排序
  });
  // 清除 校验结果
  formRef.value.clearValidate();
  // 清空表单数据
  formRef.value.resetFields();
  dialogFormVisible.value = false;
};

// 弹框中 取消按钮
const cancel = () => {
  // 清空表单并关闭弹框
  form1 = reactive({
    // 添加弹框
    id: 0,
    webName: "", // 网站名称
    webLink: "", // 网站链接
    sort: null, // 排序
  });
  // 清除 校验结果
  formRef.value.clearValidate();
  // 清空表单数据
  formRef.value.resetFields();
  dialogFormVisible.value = false;
};

// 确认添加/编辑 友情链接数据
function addToTable() {
  formRef.value
    // 校验
    .validate()
    .then(async () => {
      // 接收表单的数据
      const paramData = JSON.stringify(form1);
      if (modalType.value === 0) {
        // 添加
        await addFriendlyLinkApi(paramData);
        ElMessage({
          type: "success",
          message: "添加成功",
        });
      } else {
        // 编辑
        await editFriendlyLinkApi(paramData);
        ElMessage({
          type: "success",
          message: "编辑成功",
        });
      }
      // 新增后清空表单 并关闭弹框
      form1 = reactive({
        // 添加弹框
        id: 0,
        webName: "", // 网站名称
        webLink: "", // 网站链接
        sort: null, // 排序
      });
      // 清除 校验结果
      formRef.value.clearValidate();
      // 清空表单数据
      formRef.value.resetFields();
      dialogFormVisible.value = false;
      //重新获得表格数据
      getList();
    })
    .catch((err) => {
      console.error("表单验证失败:", err);
    });
}

// 友情链接 表格数据
const tableData = ref([]);
// 表格列
const tableCols = ref([
  {
    label: "网站名称",
    prop: "webName",
    align: "center",
    width: "460",
  },
  {
    label: "网站链接",
    prop: "webLink",
    align: "center",
    width: "460",
  },
  {
    label: "排序",
    prop: "sort",
    align: "center",
    width: "110",
  },
  {
    label: "操作",
    type: "Button",
    width: "200",
    align: "center",
    btnList: [
      {
        type: "primary",
        size: "",
        label: "编辑",
        handle: (row) => {
          editData(row);
        },
      },
      {
        type: "primary",
        size: "",
        label: "删除",
        handle: (row) => {
          deleteData(row);
        },
      },
    ],
  },
]);
// 序号列是否启用
const sortStuat = ref(true);

// const startTime = ref();
// const endTime = ref();
// let completeTime = ref();

//获取 表单站点信息 和 友情链接表格数据
const getList = async () => {
  // startTime.value = performance.now(); // 开始时间
  loading.value = true;
  const { data } = await getSiteBasicApi();
  // 站点管理表单数据
  form.id = data.id;
  form.companyName = data.companyName; // 公司名称
  form.recordNumber = data.recordNumber; // 备案号
  form.nationalCSHotline = data.nationalCSHotline; // 全国服务热线
  form.companyAddress = data.companyAddress; // 公司地址
  form.companyEmail = data.companyEmail; // 公司Email
  form.webLogo = data.webLogo;
  form.bottomImage = data.bottomImage;
  inputItems[5].fileList = [
    {
      name: "缩略图",
      url: `${window.apiConfig.VUE_APP_GATHER_URL.value}/${data.webLogo}`,
    },
  ]; //公司logo

  let datas = [];
  if (data.bottomImage) {
    datas = data.bottomImage.split(",");
  }

  inputItems[6].fileList = datas.map((path, index) => ({
    name: `图片${index + 1}`,
    url: `${window.apiConfig.VUE_APP_GATHER_URL.value}/${path}`,
  }));
  // 友情链接表格数据
  const list = [];
  if (data.friendlyLinks) {
    data.friendlyLinks.map((item) => {
      list.push({
        id: item.id,
        webName: item.webName,
        webLink: item.webLink,
        sort: item.sort,
      });
    });
  }
  tableData.value = list;
  loading.value = false;
  // endTime.value = performance.now(); // 结束时间

  // completeTime.value = endTime.value - startTime.value;
  // console.log(completeTime.value, "completeTime1111");

  // if (completeTime.value >= 200) {
  //   loading.value = true; // 如果所需时间大于等于500毫秒，则显示loading
  //   let loadingTime = setInterval(() => {
  //     loading.value = false;
  //     clearInterval(loadingTime);
  //   }, 500);
  //   // console.log(11111111);
  // } else {
  //   loading.value = false; // 如果所需时间小于500毫秒，则不显示loading
  //   // console.log(22222222);
  // }
};

//初始化表格
onMounted(() => {
  getList();
});

// 删除 友情链接数据 （消息弹出框）
const deleteData = (row) => {
  // console.log(row, "deleteData");
  ElMessageBox.confirm("是否确认删除？", "删除", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(async () => {
      ElMessage({
        type: "success",
        message: "删除成功",
      });
      const { id } = row;
      await deleteFriendlyLinkApi(id);
      getList();
    })
    .catch(() => {
      ElMessage({
        type: "info",
        message: "取消删除",
      });
    });
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.common-layout {
  // margin-bottom: 30px;
  position: relative;
  background: #ffffff;
}
// .el-card {
//   width: 99%;
//   height: 95%;
//   background: #ffffff;
// }

// 图片上传框大小
::v-deep .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
}

// 整体
.bac {
  // height: 960px;
  // background: #ffffff;
  // background: #3185ff;
}

// 小标题
.icon {
  font-weight: 500;
  font-size: 16px;
  color: #3185ff;
  line-height: 19px;
  text-align: left;
  font-style: normal;
  margin-top: 24px;
  margin-left: 14px;
  margin-bottom: 25px;
  .titleOne {
    color: #000000;
    font-weight: 600;
    font-size: 16px;
    line-height: 18.75px;
    text-align: left;
    font-style: normal;
  }
}

.formBac {
  // margin-right: 19.36%;
  // width: 100%;
  // height: ;
  // background: olivedrab;
}

// 按钮中字样式
.addWord,
.cancelWord {
  font-weight: 400;
  font-size: 14px;
  text-align: right;
  line-height: 16px;
  font-style: normal;
}
.addWord {
  color: #ffffff;
}
.cancelWord {
  color: #515d6b;
}

// 友情链接
.content_twoBac {
  width: 100%;
  // height: 450px; // 514px
  position: relative;
  // margin-bottom: -10px;
  // margin-top: 4px;
  // background: salmon;

  // 添加按钮
  .content_button {
    display: flex;
    justify-content: flex-start;
    margin-top: -4px;
    margin-left: 22px;
    .el-button {
      width: 100px;
      height: 32px;
      background: #3185ff;
      border-radius: 4px 4px 4px 4px;
    }
  } //  弹框标头
  ::v-deep .el-dialog__header {
    width: 326px;
    height: 40px; // 50
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 6px 6px 0px 0px;
    background: #f0f4fa;
  }
  ::v-deep .el-dialog__title {
    font-weight: 400;
    font-size: 16px;
    color: #03101f;
    line-height: 19px;
    text-align: left;
    font-style: normal;
    margin-left: 15px;
    position: absolute;
    top: 14px;
    // 14 15
  }

  // el-table表格
  .elmTableBac {
    // width: 1584px;
    width: 80%;
    height: 330px;
    margin-top: 10px;
    margin-left: 24px;
    margin-right: 24px;
    // background: salmon;
  }
}
// 保存按钮
.saveButton {
  margin-left: 11px;
  margin-bottom: 10px;
  padding: 10px;
  .el-button {
    width: 100px;
    height: 32px;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 16px;
    font-style: normal;
    background: #3185ff;
    border-radius: 4px 4px 4px 4px;
  }
}
// 弹框按钮
.dialog-footer {
  width: 212px;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // margin-top: 32px;
  margin-left: 103px;
  // background: wheat;
  .cancelBtn {
    width: 100px;
    height: 32px;
    font-weight: 400;
    font-size: 14px;
    color: #515d6b;
    line-height: 16px;
    font-style: normal;
    background: #c1cfe5;
    border-radius: 4px 4px 4px 4px;
  }
  .cancelBtn:hover {
    font-weight: 400;
    font-size: 14px;
    color: #515d6b;
    line-height: 16px;
    font-style: normal;
    background: #c1cfe5;
    border-radius: 4px 4px 4px 4px;
  }
  .certainBtn {
    width: 100px;
    height: 32px;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 16px;
    font-style: normal;
    background: #3185ff;
    border-radius: 4px 4px 4px 4px;
  }

  .certainBtn:hover {
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 16px;
    font-style: normal;
    background: #3185ff;
    border-radius: 4px 4px 4px 4px;
  }
}
</style>
