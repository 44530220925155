import request from '@/utils/request'


export const getLoginApi = (datas) => {
    return request({
      url: `/api/user-basic/login`,
      method: 'POST',
      data: JSON.stringify(datas)
    })
  }
  