<template>
  <el-card ref="tablePage">
    <div class="content">
      <div class="content_button">
        <el-button class="add" plain @click="handleAdd">
          <span style="font-size: 14px">新建主栏目</span>
        </el-button>
      </div>
      <div class="content_table">
        <elmTable
          :table-data="tableData"
          :table-cols="tableCols"
          :total="totalData"
          :height="height"
          :sortStuat="sortStuat"
          :paginationStatu="paginationStatu"
          v-loading="loading"
          @onCurrentPageChange="onCurrentPageChange"
          @onPageSizeChange="onPageSizeChange"
          @onLoad="onLoad"
        />
      </div>
      <!-- 表单 -->
      <el-dialog
        @close="handleClose"
        :ref="formDialogRef"
        v-model="dialogFormVisible"
        :title="modalType === 0 ? '新增' : '编辑'"
        width="500"
      >
        <elmForm
          :operateForm="form"
          :operateFormLabel="formLabel"
          :row="rowData"
          :rules="rules"
          @update:modelValue="handleFormUpdate"
          :buttonStatus="buttonStatus"
          @handleClose="handleClose"
        />
      </el-dialog>

      <!-- 表单 -->
      <el-dialog
        v-model="dialogFormVisible2"
        :title="modalType === 0 ? '新增' : '编辑'"
        width="500"
      >
        <elmForm
          :operateForm="form2"
          :operateFormLabel="formLabel2"
          :row="rowData"
          :rules="rules"
          @update:modelValue="handleFormUpdate"
          :buttonStatus="buttonStatus"
          @handleClose="handleClose"
        />
      </el-dialog>
    </div>
  </el-card>
</template>

<script setup>
import elmTable from "@/components/elmTable.vue";
import elmForm from "@/components/elmForm.vue";
import {
  addcolumnManageApi,
  editcolumnManageApi,
  deletecolumnManageApi,
  getAllColumnApi,
} from "@/api/columnManagementApi";
import { ref, reactive, onMounted, onUnmounted } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";

// 表格加载动画
const loading = ref(false);

const paginationStatu = ref(false);
const height = ref(600);
// 获取卡片高度再复制
const setTableHeight = () => {
  const outBoxHeight = Number(tablePage.value.$el.clientHeight) - 100;
  height.value = outBoxHeight;
};
const sortStuat = ref(false);
const tablePage = ref();
const rowData = ref({
  thumbnailPath: "",
  videoPath: "",
  productGallery: null,
});

//表单字段数据
let form = reactive({
  id: 0,
  columnName: "",
  columnType: "",
  columnTemplate: "",
  targetWindow: 1,
  columnPath: "",
  isShow: true,
  sort: 1,
  path: "",
});

const rules = reactive({
  columnName: [
    { required: true, message: "此为必填项 ", trigger: "blur" },
    { max: 6, message: "栏目名称不能 超过6个字", trigger: "blur" },
  ],
  columnType: [{ required: true, message: "此为必填项 ", trigger: "blur" }],
  columnTemplate: [{ required: true, message: "此为必填项 ", trigger: "blur" }],
  targetWindow: [{ required: true, message: "此为必填项 ", trigger: "blur" }],
  isShow: [{ required: true, message: "此为必填项 ", trigger: "blur" }],
  columnPath: [{ required: true, message: "此为必填项 ", trigger: "blur" }],
});

const buttonStatus = ref(false);

// 表单组件配置项
const formLabel = ref([
  {
    prop: "columnName",
    label: "栏目名称:",
    span: 24,
    width: "50%",
    disabled: false,
    type: "input",
  },
  {
    prop: "columnType",
    label: "栏目类型:",
    span: 24,
    width: "50%",
    disabled: false,
    type: "select",
    opts: [
      { key: 0, value: "首页" },
      { key: 1, value: "信息页" },
      { key: 2, value: "自定义链接" },
    ],
  },
  {
    prop: "columnPath",
    label: "栏目路径:",
    span: 24,
    width: "50%",
    disabled: false,
    type: "select",
    opts: [
      { key: 0, value: "首页" },
      { key: 1, value: "企业概况" },
      { key: 2, value: "新闻动态" },
      { key: 3, value: "核心业务" },
      { key: 4, value: "关联企业" },
      { key: 5, value: "企业文化" },
      { key: 6, value: "人力资源" },
      { key: 7, value: "精品工程" },
      { key: 8, value: "重要转载" },
      { key: 9, value: "内部管理" },
      { key: 10, value: "联系我们" },
      { key: 11, value: "湖北广电数字技术" },
      { key: 12, value: "公司简介" },
      { key: 13, value: "组织结构" },
      { key: 14, value: "企业资质" },
      { key: 15, value: "资质证书" },
      { key: 16, value: "设计咨询服务" },
      { key: 17, value: "解决方案" },
      { key: 18, value: "资质资讯" },
      { key: 19, value: "安全生产" },
      { key: 20, value: "工程动态" },
      { key: 21, value: "企业要闻" },
      { key: 22, value: "生命港湾" },
      { key: 23, value: "鼎立数智科技(北京)有限公司" },
      { key: 24, value: "湖北广电数字技术有限责任公司" },
      { key: 25, value: "公司理念" },
      { key: 26, value: "创始人介绍" },
      { key: 27, value: "员工风采" },
      { key: 28, value: "团建活动" },
      { key: 29, value: "人才培训" },
      { key: 30, value: "常年招聘" },
      { key: 31, value: "在建工程" },
      { key: 32, value: "子栏目精品工程" },
      { key: 33, value: "社会动态" },
      { key: 34, value: "资质资讯" },
      { key: 35, value: "政府资讯" },
      { key: 36, value: "政策扶持" },
      { key: 37, value: "OA办公" },
      { key: 38, value: "综合运维平台" },
    ],
  },
  {
    prop: "columnTemplate",
    label: "关联模板:",
    span: 24,
    width: "50%",
    disabled: false,
    type: "select",
    opts: [
      { key: 0, value: "首页" },
      { key: 1, value: "新闻中心" },
      { key: 2, value: "产品中心" },
      { key: 3, value: "岗位中心" },
      { key: 4, value: "内容介绍" },
      { key: 5, value: "关于我们" },
      { key: 6, value: "创始人" },
      { key: 7, value: "员工风采" },
    ],
  },
  {
    prop: "targetWindow",
    label: "目标窗口:",
    span: 24,
    width: "50%",
    disabled: false,
    type: "select",
    opts: [
      { key: 0, value: "本窗口" },
      { key: 1, value: "新窗口" },
    ],
  },
  {
    prop: "isShow",
    label: "是否显示:",
    span: 24,
    width: "50%",
    disabled: false,
    type: "select",
    opts: [
      { key: true, value: "是" },
      { key: false, value: "否" },
    ],
  },
  {
    prop: "sort",
    label: "排序:",
    span: 24,
    width: "50%",
    disabled: false,
    type: "input",
  },
]);

//创建子列表的表单字段数据
let form2 = reactive({
  id: 0,
  parentId: 0,
  colunmParent: "",
  columnName: "",
  columnPath: "",
  columnType: "",
  columnTemplate: "",
  targetWindow: "",
  isShow: true,
  sort: 1,
  path: "",
});

// 子列表的表单组件配置项
const formLabel2 = ref([
  {
    prop: "colunmParent",
    label: "上级目录:",
    span: 24,
    width: "50%",
    disabled: true,
    type: "input",
  },
  {
    prop: "columnName",
    label: "栏目名称:",
    span: 24,
    width: "50%",
    disabled: false,
    type: "input",
  },
  {
    prop: "columnPath",
    label: "栏目路径:",
    span: 24,
    width: "50%",
    disabled: false,
    type: "select",
    opts: [
      { key: 0, value: "首页" },
      { key: 1, value: "企业概况" },
      { key: 2, value: "新闻动态" },
      { key: 3, value: "核心业务" },
      { key: 4, value: "关联企业" },
      { key: 5, value: "企业文化" },
      { key: 6, value: "人力资源" },
      { key: 7, value: "精品工程" },
      { key: 8, value: "重要转载 " },
      { key: 9, value: "内部管理" },
      { key: 10, value: "联系我们" },
      { key: 11, value: "湖北广电数字技术" },
      { key: 12, value: "公司简介" },
      { key: 13, value: "组织结构" },
      { key: 14, value: "企业资质" },
      { key: 15, value: "资质证书" },
      { key: 16, value: "设计咨询服务" },
      { key: 17, value: "解决方案" },
      { key: 18, value: "资质资讯" },
      { key: 19, value: "安全生产" },
      { key: 20, value: "工程动态" },
      { key: 21, value: "企业要闻" },
      { key: 22, value: "生命港湾" },
      { key: 23, value: "鼎立数智科技(北京)有限公司" },
      { key: 24, value: "湖北广电数字技术有限责任公司" },
      { key: 25, value: "公司理念" },
      { key: 26, value: "创始人介绍" },
      { key: 27, value: "员工风采" },
      { key: 28, value: "团建活动" },
      { key: 29, value: "人才培训" },
      { key: 30, value: "常年招聘" },
      { key: 31, value: "在建工程" },
      { key: 32, value: "子栏目精品工程" },
      { key: 33, value: "社会动态" },
      { key: 34, value: "资质资讯" },
      { key: 35, value: "政府资讯" },
      { key: 36, value: "政策扶持" },
      { key: 37, value: "OA办公" },
      { key: 38, value: "综合运维平台" },
    ],
  },
  {
    prop: "columnType",
    label: "栏目类型:",
    span: 24,
    width: "50%",
    disabled: false,
    type: "select",
    opts: [
      { key: 1, value: "信息页" },
      { key: 2, value: "自定义链接" },
    ],
  },
  {
    prop: "columnTemplate",
    label: "关联模板:",
    span: 24,
    width: "50%",
    disabled: false,
    type: "select",
    opts: [
      { key: 0, value: "首页" },
      { key: 1, value: "新闻中心" },
      { key: 2, value: "产品中心" },
      { key: 3, value: "岗位中心" },
      { key: 4, value: "内容介绍" },
      { key: 5, value: "关于我们" },
      { key: 6, value: "创始人" },
      { key: 7, value: "员工风采" },
    ],
  },
  {
    prop: "targetWindow",
    label: "目标窗口:",
    span: 24,
    width: "50%",
    disabled: false,
    type: "select",
    opts: [
      { key: 0, value: "本窗口" },
      { key: 1, value: "新窗口" },
    ],
  },
  {
    prop: "isShow",
    label: "是否显示:",
    span: 24,
    width: "50%",
    disabled: false,
    type: "select",
    opts: [
      { key: true, value: "是" },
      { key: false, value: "否" },
    ],
  },
  {
    prop: "sort",
    label: "排序:",
    span: 24,
    width: "50%",
    disabled: false,
    type: "input",
  },
]);

const formDialogRef = ref();

// 表单状态
let modalType = ref(0); // 0是新增 1是编辑

// 处理子组件发射的update:modelValue事件
// 新增主栏目 和 编辑主栏目
async function handleFormUpdate(updatedFormData) {
  // 将子组件传递过来的新表单数据合并到父组件的表单数据中
  let paramData = "";
  if (updatedFormData.colunmParent) {
    const tempForm = {
      id: 0,
      parentId: 0,
      columnName: "",
      columnType: "",
      columnTemplate: "",
      targetWindow: "",
      isShow: true,
      sort: 1,
    };
    for (const key in updatedFormData) {
      if (key !== "colunmParent") {
        // 不处理 colunmParent 属性
        tempForm[key] = updatedFormData[key];
      }
    }
    paramData = tempForm;
  } else {
    for (const key in updatedFormData) {
      form[key] = updatedFormData[key];
    }
    paramData = form;
  }
  if (paramData.sort == "") {
    paramData.sort = 0;
  }
  // console.log("paramData.columnTemplate", paramData.columnTemplate);
  if (paramData.columnTemplate === "") {
    paramData.columnTemplate = -1;
  }
  // console.log("paramData", JSON.stringify(paramData));
  if (modalType.value === 0) {
    await addcolumnManageApi(JSON.stringify(paramData));
    ElMessage({
      type: "success",
      message: "新增成功",
    });
  } else {
    await editcolumnManageApi(JSON.stringify(paramData));
    ElMessage({
      type: "success",
      message: "编辑成功",
    });
  }

  // 新增后关闭弹窗 并清空表单
  for (const key in form) {
    if (key == "id") {
      form[key] = 0;
    } else {
      form[key] = "";
    }
  }

  for (const key in form2) {
    if (key == "id") {
      form2[key] = 0;
    } else {
      form2[key] = "";
    }
  }

  dialogFormVisible.value = false;
  dialogFormVisible2.value = false;
  formLabel.value[2].disabled = false;
  //重新获得表格数据
  getList();

  // console.log("form", form);
}

//  关闭表单时清除表单
const handleClose = () => {
  // 新增后关闭弹窗 并清空表单
  for (const key in form) {
    if (key == "id") {
      form[key] = 0;
    } else {
      form[key] = "";
    }
  }

  for (const key in form2) {
    if (key == "id") {
      form2[key] = 0;
    } else {
      form2[key] = "";
    }
  }
  dialogFormVisible.value = false;
  dialogFormVisible2.value = false;
  formLabel.value[2].disabled = false;
};

// 表格数据
const tableData = ref([]);

// 表格配置项
const tableCols = ref([
  { label: "序号", prop: "index", width: "60", align: "center" },
  { label: "栏目名称", prop: "columnName", align: "center" },
  { label: "类型", prop: "columnType", align: "center" },
  { label: "路径/链接", prop: "path", align: "center" },
  { label: "是否显示", prop: "isShow", width: "90", align: "center" },
  { label: "排序", prop: "sort", width: "60", align: "center" },
  { label: "创建人", prop: "createUser", align: "center" },
  { label: "创建日期", prop: "createDate", width: "180", align: "center" },
  { label: "修改人", prop: "updateUser", align: "center" },
  { label: "修改日期", prop: "updateDate", width: "180", align: "center" },
  {
    label: "操作",
    type: "Button",
    width: "250",
    align: "center",
    btnList: [
      {
        type: "primary",
        size: "",
        label: "添加子栏目",
        width: "100",
        parentHandle: true, // 父级节点可使用
        childHide: true, // 子级节点不可见
        handle: (row) => {
          addChild(row);
        },
      },
      {
        type: "primary",
        size: "",
        label: "编辑",
        parentHandle: true, // 表示所有层级都可以使用
        handle: (row) => {
          EditData(row);
        },
      },
      {
        type: "primary",
        size: "",
        label: "删除",
        parentHandle: true, // 表示所有层级都可以使用
        handle: (row) => {
          deleteData(row);
        },
      },
    ],
  },
]);
// 表格总数据
const totalData = ref(null);
// 分页数据
const params = reactive({
  pageindex: 1,
  pagesize: 999,
});
// 翻页函数
const onCurrentPageChange = (newPage) => {
  params.pageindex = newPage;
  getList();
};
// 选定页面数据量
const onPageSizeChange = (newPageSize) => {
  params.pagesize = newPageSize;
  getList();
};

//父表单是否弹出的状态
let dialogFormVisible = ref(false);

//子表单是否弹出的状态
let dialogFormVisible2 = ref(false);

// const startTime = ref();
// const endTime = ref();
// let completeTime = ref();
//获取表格数据
const getList = async () => {
  loading.value = true;
  // startTime.value = performance.now(); // 开始时间
  const { data } = await getAllColumnApi();
  const datas = data.map((item, idx) => {
    return {
      ...item,
      index: idx + 1,
    };
  });

  const aaa = datas.map((item) => {
    if (item.children && item.children.length > 0) {
      const children = item.children.map((child, idx) => {
        return {
          ...child, // 注意这里应该是扩展child而不是item
          index: idx + 1,
        };
      });

      return {
        ...item,
        children, // 重新赋值更新过的children数组
      };
    }
    return item; // 如果没有children，直接返回原始item
  });

  // console.log("aaa");
  tableData.value = aaa;
  loading.value = false;
  // endTime.value = performance.now(); // 结束时间

  // completeTime.value = endTime.value - startTime.value;

  // console.log(completeTime.value, "completeTime");

  // if (completeTime.value >= 500) {
  //   loading.value = true; // 如果所需时间大于等于500毫秒，则显示loading
  // } else {
  //   loading.value = false; // 如果所需时间小于500毫秒，则不显示loading
  // }
};

//初始化表格
onMounted(() => {
  getList();
  setTableHeight();
});
// 窗口变化的函数
//使用防抖 (setTimeout定时器)
const getWindowInfo = () => {
  setTableHeight();
};
const debounce = (fn, delay) => {
  let timer;
  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      fn();
    }, delay);
  };
};
//触发事件    触发时间（指定时间内执行事件）
const cancalDebounce = debounce(getWindowInfo, 200);
// 移除这个监听事件
onUnmounted(() => {
  //移除监听事件
  window.removeEventListener("resize", cancalDebounce);
});
window.addEventListener("resize", cancalDebounce);
//新增总栏目
const handleAdd = () => {
  modalType.value = 0;
  dialogFormVisible.value = true;
};

//新增子栏目
const addChild = (row) => {
  form2.colunmParent = row.columnName;
  form2.parentId = row.id;
  modalType.value = 0;
  dialogFormVisible2.value = true;
};

//编辑栏目
const EditData = (row) => {
  //将表格中的数据回显到表单上
  console.log("row", row);
  formLabel.value[2].disabled = true;
  for (const key in row) {
    if (Object.prototype.hasOwnProperty.call(form, key)) {
      form[key] = row[key];
    }
  }
  // console.log("row",row)
  modalType.value = 1;
  dialogFormVisible.value = true;
};

//删除栏目
const deleteData = async (row) => {
  ElMessageBox.confirm("是否确认删除", "", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(async () => {
      const { id } = row;
      await deletecolumnManageApi(id);
      getList();
      ElMessage({
        type: "success",
        message: "删除成功",
      });
    })
    .catch(() => {
      ElMessage({
        type: "info",
        message: "删除失败",
      });
    });
};
</script>

<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}
.el-card {
  ::v-deep .el-card__body {
    height: 100%;
    box-sizing: border-box;
  }
}
.content {
  .content_button {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    margin-bottom: 14px;
    // background: wheat;
    .el-button {
      width: 100px;
      height: 32px;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      line-height: 16px;
      font-style: normal;
      background: #3185ff;
      border-radius: 4px 4px 4px 4px;
    }
    .el-button:hover {
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      line-height: 16px;
      font-style: normal;
      background: #3185ff;
      border-radius: 4px 4px 4px 4px;
    }
    .el-button:focus {
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      line-height: 16px;
      font-style: normal;
      background: #3185ff;
      border-radius: 4px 4px 4px 4px;
    }
    // 设置
    .set_bac {
      width: 80px;
      height: 50px;
      // background: olivedrab;
    }
  }
  // .content_button {
  //   margin-top: 0;
  //   margin-bottom: 10px;
  //   .el-button {
  //     width: 100px;
  //     height: 36px;
  //     background: #006de6;
  //     border-radius: 4px 4px 4px 4px;
  //     font-weight: 400;
  //     font-size: 14px;
  //     color: #ffffff;
  //   }
  // }
}
</style>
