import { createRouter, createWebHistory } from "vue-router";
import { createApp } from "vue";
import App from "./App.vue";
import backendRoutes from "@/router/backendRoutes.js";
// import frontendRoutes from "@/router/frontendRoutes.js";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "../public/css/global.css";
import zhCn from "element-plus/dist/locale/zh-cn.mjs";

import BaiduMap from "vue-baidu-map-3x";

// 创建Vue Router实例并只添加后端路由
const router = createRouter({
  history: createWebHistory(),
  routes: backendRoutes,
  // 滚动条直接在body元素上 路由切换后页面返回顶部
  scrollBehavior() {
    return { top: 0 };
  },
});

// 在创建应用前获取动态路由
(async () => {
  await store.dispatch("fetchDynamicRoutes");
  // 对动态路由按照sort字段进行排序
  let sortedRoutes = [];
  if (store.state.dynamicRoutes) {
    sortedRoutes = store.state.dynamicRoutes;
  } else {
    sortedRoutes = JSON.parse(sessionStorage.getItem("routes"));
  }

  // 定义父路由对象
  const parentRoute = {
    path: "/",
    component: () => import(`@/views/websiteFrontDesk/layout/index.vue`),
    name: "home",
    redirect: "HomePage",
    children: [
      {
        path: "/search",
        component: () =>
          import("@/views/websiteFrontDesk/newsUpdates/index.vue"),
        name: "search",
      },
    ],
  };

  // 构建新的路由表并添加到父路由的children中
  Object.values(sortedRoutes).forEach((route) => {
    let childrenRoute = [];

    if (route.children && route.children.length > 0) {
      childrenRoute = route.children.map((childRoute) => {
        // console.log("aaa", `@/views/websiteFrontDesk/${childRoute.component}`);
        return {
          path: childRoute.path,
          name: childRoute.name,
          component: () =>
            import(`@/views/websiteFrontDesk/${childRoute.component}`),
        };
      });
      route.redirect = { name: childrenRoute[0].name };
    }

    // 将当前路由添加到父路由的children数组
    if (childrenRoute.length > 0) {
      parentRoute.children.push({
        path: route.path,
        name: route.name,
        children: childrenRoute,
        redirect: route.redirect,
        // 根据实际数据结构添加meta、targetWindow等属性
      });
    } else {
      parentRoute.children.push({
        path: route.path,
        name: route.name,
        component: () => import(`@/views/websiteFrontDesk/${route.component}`),
        children: childrenRoute,
        // 根据实际数据结构添加meta、targetWindow等属性
      });
    }
  });

  // 添加包含动态子路由的父路由到router实例
  router.addRoute(parentRoute);

  // 检查路由表结构
  // console.log("router", router.getRoutes()); // 输出已添加的所有路由，检查结构和属性是否正确

  const app = createApp(App);

  // 设置全局前置守卫，这里可以判断是否为后台路由再进行token验证
  router.beforeEach((to, from, next) => {
    const isBackendRoute = to.matched.some((record) => record.meta.isBackend); // 假设后台路由设置了 meta: { isBackend: true }
    const token = sessionStorage.getItem("token");
    // console.log(isBackendRoute, to, 888 );
    if (isBackendRoute) {
      if (!token && to.name !== "login") {
        next({ name: "login" });
      } else if (token && to.name === "login") {
        next({ name: "management" });
      } else {
        next();
      }
    } else {
      next(); // 如果不是后台路由，则直接进入
    }
  });
  // 注册Vue Router
  app.directive("click-outside", {
    // 当绑定的元素插入到 DOM 中时触发
    mounted(el, binding) {
      // 点击元素外部的事件处理函数
      const handleClickOutside = (event) => {
        // 判断点击的位置是否在元素以外
        if (!el.contains(event.target)) {
          // 调用绑定的值，通知组件点击了外部
          binding.value();
        }
      };

      // 添加全局的点击事件监听器
      document.addEventListener("click", handleClickOutside);

      // 在组件销毁时移除事件监听器，防止内存泄漏
      el.__clickOutsideHandler__ = handleClickOutside;
    },
    // 当绑定的元素从 DOM 中移除时触发
    unmounted(el) {
      // 移除全局的点击事件监听器
      document.removeEventListener("click", el.__clickOutsideHandler__);
      delete el.__clickOutsideHandler__;
    },
  });

  app
    .use(router)
    .use(ElementPlus, { locale: zhCn })
    .use(store)
    .use(BaiduMap, {
      // ak 是在百度地图开发者平台申请的密钥
      ak: "PC4cupSre7lThPN9j8XYmGMC9d7BWTGx",
    })
    .mount("#app");
})();
