<template>
  <el-card>
    <div class="content">
      <el-scrollbar height="54px" width="500px">
        <div class="constent_search">
          <el-form ref="formRef" :model="searchData" @submit="handleSearch">
            <el-form-item label="所属栏目" prop="belongColumnId">
              <el-cascader
                v-model="searchData.belongColumnId"
                :props="propsData"
                :options="structuredItems"
                clearable
                placeholder="请选择"
                :show-all-levels="false"
              />
            </el-form-item>

            <el-form-item label="标题" prop="title">
              <el-input
                v-model="searchData.title"
                placeholder="请输入"
              ></el-input>
            </el-form-item>

            <el-form-item label="状态" prop="status">
              <el-select
                clearable
                v-model="searchData.status"
                placeholder="请选择"
              >
                <el-option label="已上架" value="0" />
                <el-option label="未上架" value="1" />
              </el-select>
            </el-form-item>

            <el-form-item label="是否显示" prop="isDisplay">
              <el-select
                clearable
                v-model="searchData.isDisplay"
                placeholder="请选择"
              >
                <el-option label="显示" :value="true" />
                <el-option label="不显示" :value="false" />
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="handleSearch"
                ><span style="font-size: 14px">查询</span></el-button
              >
              <el-button @click="handleResetting"
                ><span style="font-size: 14px">重置</span></el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </el-scrollbar>
      <div class="content_button">
        <el-button class="add" plain @click="handleAdd">
          <span style="font-size: 14px">新建</span>
        </el-button>
      </div>
      <div class="content_table">
        <elmTable
          :table-data="tableData"
          :table-cols="tableCols"
          :sortStuat="sortStuat"
          :total="totalData"
          :height="height"
          :paginationStatu="paginationStatu"
          v-loading="loading"
          @onCurrentPageChange="onCurrentPageChange"
          @onPageSizeChange="onPageSizeChange"
        />
      </div>
      <!-- 表单 -->
      <el-dialog
        fullscreen
        @close="handleClose"
        v-model="dialogFormVisible"
        :title="modalType === 0 ? '新增' : '编辑'"
        width="800"
      >
        <div class="preview">
          <div class="preview_header">预览</div>
          <div v-if="form.content" v-html="form.content"></div>
        </div>
        <elmForm
          ref="childRef"
          v-if="dialogFormVisible"
          :operateForm="form"
          :operateFormLabel="formLabel"
          :statusId="statusId"
          :buttonStatus="buttonStatus"
          :row="rowData"
          :rules="rules"
          @update:modelValue="handleFormUpdate"
          @handleClose="handleClose"
          @handleChange="handleChange"
          @handleRemove="handleRemove"
        />
      </el-dialog>
    </div>
  </el-card>
</template>
<script setup>
import elmTable from "@/components/elmTable.vue";
import elmForm from "@/components/elmForm.vue";
import { uploadFilesApi } from "@/api/contentManageApi";
import { getcolumnManageApi } from "@/api/columnManagementApi";
import {
  getContentManageApi,
  addContentManageApi,
  editContentManageApi,
  deleteContentManageApi,
  statuManageApi,
} from "@/api/contentManageApi";
import { onMounted, ref, reactive } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";

// 表格加载动画
const loading = ref(false);

const buttonStatus = ref(true);
const sortStuat = ref(true);
const paginationStatu = ref(true);
const childRef = ref();
const height = ref(450);
// 文件上传逻辑
const uploadFile = async (file) => {
  let fileData = new FormData();
  fileData.append("files", file);
  const datas = await uploadFilesApi(fileData);
  return datas;
};

const handleChange = async (file, fileLists, type) => {
  // 调用uploadFile上传文件
  // console.log("type",type)
  const uploadedUrl = await uploadFile(file.raw);
  // console.log("uploadedUrl",uploadedUrl,fileLists)
  if (uploadedUrl) {
    if (type === "videoPath") {
      // 处理视频文件
      if (Array.isArray(form.videoPath)) {
        form.videoPath.push(uploadedUrl.data);
      } else {
        form.videoPath = form.videoPath.split(",");
        form.videoPath = form.videoPath.filter((item) => item !== "");
        form.videoPath.push(uploadedUrl.data);
        // console.log("form.videoPath",form.videoPath)
      }
    } else if (type === "thumbnailPath") {
      // 处理图片文件
      form.thumbnailPath = uploadedUrl.data;
    }
  }
};

// 删除图片 视频的逻辑
const handleRemove = (UploadFile) => {
  let baseUrlToRemove = `${window.apiConfig.VUE_APP_GATHER_URL.value}/`;
  const url = UploadFile.url.replace(baseUrlToRemove, "");

  // console.log("url",url)
  if (Array.isArray(form.videoPath)) {
    form.videoPath = form.videoPath((link) => !link.includes(url));
    // console.log("form.videoPath",form.videoPath)
  } else {
    let data = form.videoPath.split(",");
    data = data.filter((item) => item !== "");
    form.videoPath = data.filter((link) => !link.includes(url));
    form.videoPath = form.videoPath.filter(
      (link) => !link.includes(UploadFile.name)
    );
    // console.log("form.videoPath",form.videoPath)
  }
};

// 搜索表单
const formRef = ref();
const searchData = ref({
  belongColumnId: null,
  title: "",
  status: null,
  isDisplay: null,
});
const propsData = {
  checkStrictly: true,
};

// 配置所属栏目
const result = {};
const structuredItems = ref([]);
// 配置所属栏目
const handleOpts = async () => {
  const parmas = {
    pageindex: 1,
    pagesize: 100,
  };
  // 配置所属栏目
  const { data } = await getcolumnManageApi(parmas);
  data.items.forEach((item) => {
    const { id, columnName, parentId } = item;
    // 如果是父栏目
    if (parentId === 0) {
      result[id] = {
        value: id,
        label: columnName,
        children: [],
      };
    }
  });
  data.items.forEach((item) => {
    const { id, columnName, parentId } = item;
    if (parentId !== 0) {
      // 如果是子栏目
      if (result[parentId]) {
        // 找到父栏目并将子栏目添加进去
        // console.log(" result[parentId]", result[parentId])
        result[parentId].children.push({ value: id, label: columnName });
      }
    }
  });

  structuredItems.value = Object.values(result);
};

onMounted(() => {
  handleOpts();
});

//表单字段数据
let form = reactive({
  id: 0,
  belongColumnId: "",
  videoPath: [],
  content: "",
  isDisplay: true,
  status: null,
  contentManageType: 3,
  baiduMapsAddress: "",
});
const rules = reactive({
  belongColumnId: [{ required: true, message: "此为必填项 ", trigger: "blur" }],
  content: [{ required: true, message: "此为必填项 ", trigger: "blur" }],
});
// 表单组件配置项
const formLabel = ref([
  {
    prop: "belongColumnId",
    label: "所属栏目:",
    span: 24,
    width: "50%",
    type: "cascader",
    opts: structuredItems,
  },
  {
    prop: "videoPath",
    label: "视频:",
    span: 24,
    width: "50%",
    disabled: false,
    type: "upload",
    filetype: "picture",
    upload: false,
    fileList2: [],
  },
  {
    prop: "baiduMapsAddress",
    label: "百度地图地址:",
    span: 24,
    width: "48%",
    disabled: false,
    type: "input",
  },
  {
    prop: "content",
    label: "内容:",
    span: 24,
    width: "10%",
    disabled: false,
    type: "Editor",
  },
  {
    prop: "isDisplay",
    label: "是否显示:",
    span: 24,
    width: "48%",
    disabled: false,
    type: "select",
    opts: [
      { key: true, value: "是" },
      { key: false, value: "否" },
    ],
  },
]);

// 表格数据
const tableData = ref([]);
const tableCols = ref([
  { label: "所属栏目", prop: "belongColumnName", align: "center" },
  { label: "状态", prop: "status", align: "center" },
  { label: "是否显示", prop: "isDisplay", width: "90", align: "center" },
  { label: "排序", prop: "sort", width: "60", align: "center" },
  { label: "创建人", prop: "createUser", align: "center" },
  { label: "创建日期", prop: "createDate", width: "180", align: "center" },
  { label: "修改人", prop: "updateUser", align: "center" },
  { label: "修改日期", prop: "updateDate", width: "180", align: "center" },
  {
    label: "操作",
    type: "Button",
    width: "200",
    align: "center",
    btnList: [
      {
        type: "primary",
        size: "",
        label: "下架",
        label2: "上架",
        parentHandle: true, // 父级节点可使用
        handle: (row) => {
          getStatus(row);
        },
      },
      {
        type: "primary",
        size: "",
        label: "编辑",
        label2: "编辑",
        parentHandle: true, // 表示所有层级都可以使用
        handle: (row) => {
          EditData(row);
        },
      },
      {
        type: "primary",
        size: "",
        label: "删除",
        label2: "删除",
        parentHandle: true, // 表示所有层级都可以使用
        handle: (row) => {
          deleteData(row);
        },
      },
    ],
  },
]);

// 表单状态
let modalType = ref(0); // 0是新增 1是编辑

// 处理子组件发射的update:modelValue事件
const handleFormUpdate = async (formData) => {
  if (formData.videoPath) {
    formData.videoPath = formData.videoPath.join(",");
  }

  if (Array.isArray(formData.belongColumnId)) {
    formData.belongColumnId =
      formData.belongColumnId[formData.belongColumnId.length - 1];
  }

  if (formData.sort == "") {
    formData.sort = 0;
  }
  let paramData = "";
  paramData = JSON.stringify(formData);

  if (modalType.value === 0) {
    await addContentManageApi(paramData);
    ElMessage({
      type: "success",
      message: "新增成功",
    });
  } else {
    await editContentManageApi(paramData);
    ElMessage({
      type: "success",
      message: "编辑成功",
    });
  }
  // 新增后关闭弹窗 并清空表单
  for (const key in form) {
    if (key == "id") {
      form[key] = 0;
    } else if (key === "contentManageType") {
      form[key] = 3;
    } else {
      form[key] = "";
    }
  }
  dialogFormVisible.value = false;
  getList();
};

const params = reactive({
  contentmanagetype: 3,
  pageindex: 1,
  pagesize: 10,
  belongColumnId: null,
  title: "",
  status: null,
  isDisplay: null,
});

// 请求数据
const getList = async () => {
  loading.value = true;
  const { data } = await getContentManageApi(params);
  const datas = data.items.map((item, idx) => {
    return {
      ...item,
      index: idx + 1,
    };
  });
  tableData.value = datas;
  totalData.value = data.totalCount;
  loading.value = false;
};
onMounted(() => {
  getList();
});

// 查询数据
const handleSearch = () => {
  if (searchData.value.belongColumnId) {
    searchData.value.belongColumnId =
      searchData.value.belongColumnId[
        searchData.value.belongColumnId.length - 1
      ];
  }
  params.belongColumnId = searchData.value.belongColumnId;
  params.title = searchData.value.title;
  params.status = searchData.value.status;
  params.isDisplay = searchData.value.isDisplay;
  getList();
};

//  重置数据
const handleResetting = () => {
  for (const key in searchData) {
    if (key === "contentmanagetype") {
      form[key] = 3;
    }
    form[key] = "";
  }
  handleSearch();
};

const rowData = ref({
  thumbnailPath: "",
  videoPath: "",
  productGallery: null,
});
const statusId = ref(0);
//编辑
let rowDatas = "";
const EditData = (row) => {
  //将表格中的数据回显到表单上
  buttonStatus.value = false;
  rowData.value = row;
  statusId.value = statusId.value + 1;
  // console.log("row",row)

  rowDatas = JSON.parse(JSON.stringify(row));
  let baseUrlToRemove = `${window.apiConfig.VUE_APP_GATHER_URL.value}/`;
  rowDatas.thumbnailPath = rowDatas.thumbnailPath.replace(baseUrlToRemove, "");
  // console.log("aaa",rowDatas)
  form.belongColumnId = rowDatas.belongColumnName;
  for (const key in rowDatas) {
    if (Object.prototype.hasOwnProperty.call(form, key)) {
      form[key] = rowDatas[key];
    }
  }
  // console.log("form",form)
  modalType.value = 1;
  dialogFormVisible.value = true;
};

// 改变状态
const getStatus = async (row) => {
  let statusName = "";
  if (row.status == 1) {
    statusName = "上架";
  } else {
    statusName = "下架";
  }
  ElMessageBox.confirm(`是否${statusName}`, "", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(async () => {
      let { status, id } = row;
      if (status === 1) {
        status = 0;
      } else {
        status = 1;
      }
      const params = {
        status: status,
        id: id,
      };

      await statuManageApi(params);
      getList();
      ElMessage({
        type: "success",
        message: `${statusName}成功`,
      });
    })
    .catch(() => {
      ElMessage({
        type: "info",
        message: `${statusName}失败`,
      });
    });
};
// 删除数据
const deleteData = (row) => {
  ElMessageBox.confirm("是否确认删除", "", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(async () => {
      const { id } = row;
      await deleteContentManageApi(id);
      getList();
      ElMessage({
        type: "success",
        message: "删除成功",
      });
    })
    .catch(() => {
      ElMessage({
        type: "info",
        message: "删除失败",
      });
    });
};

const totalData = ref(null);

// 翻页函数
const onCurrentPageChange = (newPage) => {
  params.pageindex = newPage;
  getList();
};
// 选定页面数据量
const onPageSizeChange = (newPageSize) => {
  params.pagesize = newPageSize;
  getList();
};

const handleClose = () => {
  // 新增后关闭弹窗 并清空表单
  rowData.value = {
    thumbnailPath: "",
    videoPath: "",
    productGallery: null,
  };


  for (const key in form) {
    if (key == "id") {
      form[key] = 0;
    } else if (key === "contentManageType") {
      form[key] = 3;
    } else {
      form[key] = "";
    }
  }
  dialogFormVisible.value = false;
};

let dialogFormVisible = ref();
//新增总栏目
const handleAdd = () => {
  buttonStatus.value = true;
  modalType.value = 0;
  dialogFormVisible.value = true;
};
</script>

<style lang="scss"  scoped>
* {
  margin: 0;
  padding: 0;
}
.el-card {
  width: 99%;
  height: 95%;
}
.content {
  .constent_search {
    .el-form {
      height: 50px !important;
      display: flex;
      flex-wrap: nowrap;
      .el-form-item {
        flex: 1;
        // margin: 10px 15px;
        .el-button--primary {
          background: #3185ff;
        }
        .el-button {
          width: 100px;
          height: 32px;
          border-radius: 4px 4px 4px 4px;
        }
      }
    }
  }
  .content_button {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    margin-bottom: 14px;
    // background: wheat;
    .el-button {
      width: 100px;
      height: 32px;
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      line-height: 16px;
      font-style: normal;
      background: #3185ff;
      border-radius: 4px 4px 4px 4px;
    }
    .el-button:hover {
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      line-height: 16px;
      font-style: normal;
      background: #3185ff;
      border-radius: 4px 4px 4px 4px;
    }
    .el-button:focus {
      font-weight: 400;
      font-size: 14px;
      color: #ffffff;
      line-height: 16px;
      font-style: normal;
      background: #3185ff;
      border-radius: 4px 4px 4px 4px;
    }
    // 设置
    .set_bac {
      width: 80px;
      height: 50px;
      // background: olivedrab;
    }
  }
  // .content_button {
  //   margin: 20px 0;
  //   .el-button {
  //     width: 100px;
  //     height: 36px;
  //     background: #006de6;
  //     border-radius: 4px 4px 4px 4px;
  //     font-weight: 400;
  //     font-size: 14px;
  //     color: #ffffff;
  //   }
  // }
  .content_table {
    width: 100%;
    height: 650px;
  }
  .el-dialog {
    padding: 0;
    .el-dialog__header {
      padding: 0;
      width: 100% !important;
      height: 50px !important;
      background: #f0f4fa !important;
      .el-dialog__title {
        font-size: 16px;
        color: #03101f;
        font-weight: 500;
        line-height: 50px;
        margin-left: 10px;
      }
    }
    .el-dialog__body {
      padding: 5px;
    }
  }
}

.preview {
  width: 900px;
  border-right: 1px solid #3185ff;

  // background: #03101F
  .preview_header {
    font-size: 20px;
    font-weight: 600;
    margin-left: 20px;
    margin-bottom: 20px;
  }
}

.add {
  height: 32px !important;
  background: #3185ff !important;
  border-radius: 4px 4px 4px 4px !important;
  font-size: 14px !important;
  color: #ffffff !important;
  line-height: 16px !important;
  font-weight: 400 !important;
}
</style>