export const columnPathMapping = {
  0: "HomePage",
  1: "CompanyProfile",
  2: "NewsCenter",
  3: "CoreBusiness",
  4: "Affiliates",
  5: "CorporateCulture",
  6: "HumanResource",
  7: "BoutiqueEngineering",
  8: "ImportantReprints",
  9: "InternalManagement",
  10: "ContactUs",
  11: "HubeiTechnology",
  12: "CompanyIntro",
  13: "OrganizationalStructure",
  14: "EnterpriseQualification",
  15: "QualificationCertificate",
  16: "DesignConsultingServices",
  17: "Solution",
  18: "QualificationNews",
  19: "SafetyProduction",
  20: "ProjectNews",
  21: "CorporateNews",
  22: "LifeHarbor",
  23: "DingLiDigitalCoLtd",
  24: "HubeiBroadcastingCoLtd",
  25: "CompanyPhilosophy",
  26: "FounderIntroduction",
  27: "EmployeeStyle",
  28: "TeamBuilding",
  29: "TalentTraining",
  30: "YearRoundRecruitment",
  31: "UnderConstructionProjects",
  32: "BoutiqueProjects",
  33: "SocialDynamics",
  34: "Qualification",
  35: "GovernmentInformation",
  36: "PolicySupport",
  37: "OAOffice",
  38: "IntegratedOperationMaintenancePlatform",
};

export const columnTemplateMapping = {
  0: "home/index.vue",
  1: "newsUpdates/index.vue",
  2: "excellentProject/excellentProject.vue",
  3: "humanResources/humanResources.vue",
  4: "content/index.vue",
  5: "aboutUs/aboutUs.vue",
  6: "content/founderIntro.vue",
  7: "content/staffStyle.vue",
  10: "/",
};
