<template>
  <el-card>
    <div class="common-layout">
      <!-- 日志管理 -->
      <div style="display: flex; flex-wrap: nowrap">
        <!-- 登录账号 -->
        <el-form
          ref="formRef"
          :model="form"
          label-width="auto"
          style="width: 308px; height: 32px; margin-top: 8px"
        >
          <el-form-item label="登录账号">
            <el-input
              v-model.trim="datas.params.loginAccount"
              placeholder="请输入"
              style="width: 240px; height: 32px"
            />
          </el-form-item>
        </el-form>
        <!-- 按钮 -->
        <div class="button_box">
          <el-button class="searchButton" @click="searchButton"
            ><span style="font-size: 14px">查询</span></el-button
          >
          <el-button class="resetButton" @click="resetButton"
            ><span style="font-size: 14px">重置</span></el-button
          >
        </div>
      </div>
      <!-- 设置 -->
      <!-- 表格 -->
      <div class="logManangeTable">
        <elmTable
          :table-data="tableData"
          :table-cols="tableCols"
          :total="totalData"
          :sortStuat="sortStuat"
          :paginationStatu="paginationStatu"
          v-loading="loading"
          @onCurrentPageChange="onCurrentPageChange"
          @onPageSizeChange="onPageSizeChange"
        />
      </div>
    </div>
  </el-card>
</template>

<script setup>
import elmTable from "@/components/elmTable.vue";
import {
  // getLogManagementApi, // 日志管理 表格数据
  getByLoginAccountApi, // 根据登录账号模糊查询
} from "@/api/logManagementApi";
import { reactive, ref, onMounted } from "vue";

// 表格加载动画
const loading = ref(false);
// 是否显示分页
const paginationStatu = ref(false);

const datas = reactive({
  params: {
    loginAccount: "", // 登录账号
    pageindex: 1,
    pagesize: 10,
  },
});

// 搜索
const formRef = ref();

// 查询
const searchButton = async () => {
  getList();
};

// 日志管理 表格数据
const tableData = ref([]);

const tableCols = ref([
  {
    label: "用户名",
    prop: "userName",
    align: "center",
    width: "230",
    required: true,
  },
  {
    label: "登录账号",
    prop: "loginAccount",
    align: "center",
    width: "230",
    required: true,
  },
  {
    label: "时间",
    prop: "time",
    align: "center",
    width: "265",
    required: false,
  },
  {
    label: "IP地址",
    prop: "ipAddress",
    align: "center",
    width: "265",
    required: false,
  },
  {
    label: "请求方法",
    prop: "postMethod",
    align: "center",
    width: "530",
    required: false,
  },
]);

const sortStuat = ref(true);

// 表格总数据
const totalData = ref(null);

// const getListOrSearch = ref(0); // 0 所有 1 查询

// 翻页函数
const onCurrentPageChange = (newPage) => {
  datas.params.pageindex = newPage;
  getList();
};
// 选定页面数据量
const onPageSizeChange = (newPageSize) => {
  datas.params.pagesize = newPageSize;
  getList();
};

// const startTime = ref();
// const endTime = ref();
// let completeTime = ref();
//获取表格数据
const getList = async () => {
  // getListOrSearch.value = 0;
  // startTime.value = performance.now(); // 开始时间
  loading.value = true;
  const { data } = await getByLoginAccountApi(datas.params);
  totalData.value = data.totalCount;
  tableData.value = data.items;
  loading.value = false;
  // endTime.value = performance.now(); // 结束时间

  // completeTime.value = endTime.value - startTime.value;

  // console.log(completeTime.value, "completeTime");

  // if (completeTime.value >= 500) {
  //   loading.value = true; // 如果所需时间大于等于500毫秒，则显示loading
  //   let loadingTime = setInterval(() => {
  //     loading.value = false;
  //     clearInterval(loadingTime);
  //   }, 500);
  //   // console.log(11111111);
  // } else {
  //   loading.value = false; // 如果所需时间小于500毫秒，则不显示loading
  //   // console.log(22222222);
  // }
  // console.log(`getList完成需要的时间： ${endTime.value - startTime.value}毫秒`);
};

//初始化表格
onMounted(() => {
  getList();
});

// 重置
const resetButton = () => {
  datas.params.loginAccount = "";
  getList();
};
</script>

<style lang="scss" scoped >
* {
  margin: 0;
  padding: 0;
}

.common-layout {
  height: 100%;
  // background: #ffffff;
  // background: wheat;
}
.el-card {
  width: 99%;
  height: 95%;
  background: #ffffff;
}

// 表单按钮
.button_box {
  width: 208px;
  height: 32px;
  margin-top: 8px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // background: olive;
  .searchButton {
    width: 100px;
    height: 32px;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
    font-style: normal;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #3185ff;
    background: #3185ff;
    text-align: right;
  }
  .searchButton:hover {
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    font-style: normal;
    background: #3185ff;
    border-radius: 4px 4px 4px 4px;
  }
  .searchButton:focus {
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    font-style: normal;
    background: #3185ff;
    border-radius: 4px 4px 4px 4px;
  }
  .resetButton {
    width: 100px;
    height: 32px;
    font-weight: 400;
    font-size: 14px;
    color: #3185ff;
    line-height: 16px;
    font-style: normal;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #3185ff;
  }
  .resetButton:hover {
    font-weight: 400;
    font-size: 14px;
    color: #3185ff;
    line-height: 16px;
    font-style: normal;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #3185ff;
    background: #ffffff;
  }
  .resetButton:focus {
    font-weight: 400;
    font-size: 14px;
    color: #3185ff;
    line-height: 16px;
    font-style: normal;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #3185ff;
    background: #ffffff;
  }
}
// 表格
.logManangeTable {
  // width: 1584px;
  width: 100%;
  height: 700px;
  margin-top: 20px;
  margin-right: 24px;
  // background: sandybrown;
}
</style>