import request from "@/utils/request";

// 查询站点信息 和 友情链接表格数据
export const getSiteBasicApi = (data) => {
  return request({
    url: `/api/site-basic/site-basic`,
    method: "GET",
    params: data,
  });
};

// 插入站点信息
export const addSiteBasicApi = (data) => {
  return request({
    url: `/api/site-basic/site-basic`,
    method: "POST",
    data: data,
  });
};

// 站点信息 上传图片
export const uploadFilesApi = (data) => {
  return request({
    url: `/api/files/upload-files`,
    method: "POST",
    data: data,
  });
};

// 编辑一行
export const editFriendlyLinkApi = (data) => {
  return request({
    url: `/api/site-friendly-link/site-friendly-link`,
    method: "PUT",
    data: data,
  });
};

// 删除一行
export const deleteFriendlyLinkApi = (data) => {
  return request({
    url: `/api/site-friendly-link/site-friendly-link`,
    method: "DELETE",
    params: {
      id: data,
    },
  });
};

// 添加一行
export const addFriendlyLinkApi = (data) => {
  return request({
    url: `/api/site-friendly-link/site-friendly-link`,
    method: "POST",
    data: data,
  });
};

// end

// 分页查询友情链接
export const getFriendlyLinkApi = (data) => {
  return request({
    url: `/api/site-friendly-link/friendly-link-list`,
    method: "GET",
    params: data,
  });
};
