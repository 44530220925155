export const  getDate = ()=>{
    const now = new Date

  // 获取年份
  const year = now.getFullYear()

  // 获取月份（注意，月份从0开始，所以要加1）
  const month = now.getMonth() + 1

  // 获取日期
  const date = now.getDate()

  const tf = function (i) {
    return (i < 10 ? '0' : '') + i
  }

  // 获取小时
  const hours = tf(now.getHours())

  // 获取分钟
  const minutes = tf(now.getMinutes())

  // 获取秒数
  const seconds = tf(now.getSeconds())

  return [
    `${year}/${month}/${date}`,
    `${hours}:${minutes}:${seconds}`
  ]

}