<template>
  <el-menu class="el-menu-vertical-demo">
    <div class="title">鼎立官网后台管理</div>
    <div class="line"></div>
    <div class="wordBac">
      <img src="../assets/title_icon.png" style="width: 24px; height: 24px" />
      <div class="word">网站管理</div>
    </div>
    <div class="elBac" v-for="item in navData" :key="item.name">
      <el-menu-item
        :class="$route.path === item.path ? 'is-active' : ''"
        v-if="item.children == null"
        @click="push(item)"
        :index="item.name"
      >
        <i :class="`el-icon-${item.icon}`"></i>
        <span slot="title">{{ item.name }}</span>
      </el-menu-item>

      <el-sub-menu v-else :index="item.name">
        <template #title>
          <i :class="`el-icon-${item.icon}`"></i>
          <span>{{ item.name }}</span>
        </template>
        <el-menu-item-group
          v-for="sonItem in item.children"
          :key="sonItem.name"
        >
          <el-menu-item
            :class="$route.path === sonItem.path ? 'is-active' : ''"
            @click="push(sonItem)"
            :index="sonItem.name"
            >{{ sonItem.name }}</el-menu-item
          >
        </el-menu-item-group>
      </el-sub-menu>
    </div>
  </el-menu>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

// 获取 store 实例
const store = useStore();

const navData = ref([
  {
    id: 1,
    name: "站点管理",
    path: "siteAdministration",
    pathId: 0,
  },
  {
    id: 2,
    name: "栏目管理",
    path: "columnManagement",
    pathId: 0,
  },
  {
    name: "内容管理",
    path: "siteAdministration",
    children: [
      {
        id: 3,
        name: "新闻中心",
        path: "newsCenter",
        pathId: 1,
      },
      {
        id: 4,
        name: "产品中心",
        path: "productCenter",
        pathId: 1,
      },
      {
        id: 5,
        name: "岗位中心",
        path: "jobCenter",
        pathId: 1,
      },
      {
        id: 6,
        name: "内容介绍",
        path: "contentIntroduction",
        pathId: 1,
      },
      {
        id: 7,
        name: "Banner管理",
        path: "bannerManagement",
        pathId: 1,
      },
    ],
  },
  {
    id: 8,
    name: "用户管理",
    path: "userManagement",
    pathId: 0,
  },
  {
    id: 9,
    name: "日志管理",
    path: "logManagement",
    pathId: 0,
  },
]);

const $router = useRouter();

const push = (item) => {
  store.commit("addTagData", item);
  $router.push(item.path);
};
</script>

<style lang="scss"  >
* {
  margin: 0;
  padding: 0;
}
.el-menu {
  background-color: rgba(0, 0, 0, 0) !important;
}
.title {
  color: #c9deff;
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: left;
  font-style: normal;
  // margin-top: 24px;
  margin-left: 28px;
}
.line {
  width: 192px;
  height: 1px;
  background: #3185ff;
  margin-top: 12px;
  margin-left: 24px;
}
.wordBac {
  width: 107px;
  height: 25px;
  margin-top: 25px;
  margin-left: 24px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .word {
    font-weight: bold;
    font-size: 18px;
    color: #3185ff;
    line-height: 21px;
    text-align: left;
    font-style: normal;
  }
}

.elBac {
  .el-menu-item {
    &:hover {
      background: linear-gradient(
        -90deg,
        rgba(49, 133, 255, 0.5) 0%,
        rgba(0, 0, 0, 0) 100%
      ) !important;
    }
  }
  .el-sub-menu__title {
    &:hover {
      background: linear-gradient(
        -90deg,
        rgba(49, 133, 255, 0.5) 0%,
        rgba(0, 0, 0, 0) 100%
      ) !important;
    }
  }
  .el-menu-item.is-active {
    background: linear-gradient(
      -90deg,
      rgba(49, 133, 255, 0.5) 0%,
      rgba(0, 0, 0, 0) 100%
    ) !important;
    color: #3185ff !important;
    border-radius: 0px 0px 0px 0px !important;
    font-weight: 500;
  }
}
// .el-menu-item.is-active {
//   background: linear-gradient(
//     -90deg,
//     rgba(49, 133, 255, 0.5) 0%,
//     rgba(0, 0, 0, 0) 100%
//   ) !important;
//   color: #3185ff !important;
//   border-radius: 0px 0px 0px 0px !important;
//   font-weight: 500;
// }
</style>