<template>
  <div class="content">
    <div class="content-right">
      <div @click="backHome" class="back-home"></div>
      <div class="time">
        <p>{{ currentDateTime[0] }}</p>
        <p>{{ currentDateTime[1] }}</p>
      </div>
      <div class="border">

      </div>
      <div class="user-name"> <p>用户:</p>  {{ store.useName ? store.useName : data}}</div>
      <div @click="handelSet" class="set"></div>
      <div @click="handelSet" style="cursor: pointer;">退出</div>
    </div>
  </div>
</template>

<script setup>
import { getDate } from "@/utils/utils.js";
import { useRouter } from "vue-router";
import { useStore } from 'vuex'
import { ref } from "vue";
import { ElMessage, ElMessageBox } from 'element-plus'


// 获取 store 实例
const store = useStore();

const data = ref(JSON.parse(localStorage.getItem('useName')))

const currentDateTime = ref(getDate());
// 定时器
setInterval(() => {
  currentDateTime.value = getDate();
}, 1000);

const $router = useRouter();
const handelSet = () => {
  ElMessageBox.confirm(
      '是否登出',
      '',
      {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }
    )
      .then( async() => {
        sessionStorage.removeItem("token");
        localStorage.removeItem("tagData");
        $router.push("/login");
        ElMessage({
          type: 'success',
          message: '登出成功',
        })
      })
      .catch(() => {
        ElMessage({
          type: 'info',
          message: '登出失败',
        })
      })

};

const backHome =() =>{
  $router.push("/HomePage")
}
</script>

<style lang="scss"  scoped >
* {
  margin: 0;
  padding: 0;
}
.content {
  width: 100%;
  height: 50px;

  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.content-right {
  margin-right: 16px;
  height: 30px;
  display: flex;
  align-items: center;
  .back-home {
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin-right: 8px;
    background-image: url("../assets/point.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .border{
    width: 1px;
    height: 24px;
    background: #ABC1E0;
    margin:0 24px ;
  }
  .time {
    font-size: 14px;
    color:#03101F;
    font-weight: 400;
    text-align: center;
   
    p {
     display: inline-block;
     margin-left: 10px;
    }
  }
  .user-name{
    margin-right:40px ;
    font-size: 14px;
    font-weight: 600;
    color: rgba(3,16,31,1);
    p {
      font-weight: 400;
     display: inline-block;
     color: rgba(3,16,31,0.65);
    }
  }
  .set {
    background-image: url("../assets/back.png");
    margin-right: 9px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
</style>