<!-- BaseForm.vue -->
<template>
  <el-form
    style="width: 600px"
    ref="formRef"
    :model="formData"
    :rules="props.rules"
    @submit.prevent="handleSubmit"
  >
    <el-row>
      <el-col
        v-for="item in computedFormLabel"
        :span="item.span"
        :key="item.prop"
      >
        <el-form-item
          :label="item.label.indexOf(':') > 0 ? item.label : item.label + ':'"
          :prop="item.prop"
        >
          <!-- type==inpyt -->
          <el-input
            v-model.trim="formData[item.prop]"
            placeholder="请输入"
            :disabled="item.disabled"
            :style="{ width: item.width }"
            v-if="item.type === 'input'"
          >
          </el-input>
          <!-- 练级选择器 -->
          <div v-if="item.type === 'cascader'">
            <el-cascader
              v-model.trim="formData[item.prop]"
              :props="propsData"
              :options="item.opts"
              placeholder="请选择 "
              :show-all-levels="false"
            />
          </div>
          <!-- type==select-->
          <el-select
            :style="{ width: item.width }"
            v-model="formData[item.prop]"
            v-if="item.type === 'select'"
            clearable
            placeholder="请选择"
            :disabled="item.disabled"
          >
            <el-option
              v-for="item2 in item.opts"
              :key="item2.key"
              :label="item2.value"
              :value="item2.key"
            />
          </el-select>
          <!-- 上传图片/文件 -->
          <div v-if="item.type === 'upload'">
            <el-upload
              ref="uploader"
              :http-request="handleRequest"
              :list-type="item.filetype"
              :auto-upload="item.upload"
              :on-remove="handleRemove"
              :on-change="
                (file, fileList) => handleChange(file, fileList, item.prop)
              "
              multiple
              :limit="item.limit"
              :file-list="
                item.filetype === 'picture'
                  ? videoFile
                  : item.prop === 'thumbnailPath'
                  ? thumbnailFile
                  : imgFile
              "
              :on-preview="handlePreview"
            >
              <div
                v-if="item.prop !== 'videoPath'"
                style="font-size: 45px; color: #c1cfe5"
              >
                +
              </div>
              <el-button v-if="item.prop === 'videoPath'" type="primary"
                >上传视频
              </el-button>
            </el-upload>
            <el-dialog v-model="dialogVisible3">
              <img w-full :src="dialogImageUrl" />
            </el-dialog>
            <el-button
              class="button"
              @click="isExpand"
              v-if="item.prop === 'productGallery' && (imgFile.length > 8 || imgNumber > 8)"
              style="margin: 10px 0"
            >
            
              {{ expand === false ? "展开" : "收起" }}
            </el-button>
          </div>
          <!-- 富文本 -->
          <div
            v-if="item.type === 'Editor'"
            style="width: 600px; border: 1px solid #ccc; z-index: 10010; position: relative"
          >
            <div>
              <Toolbar
                style=" border-bottom: 1px solid #ccc"
                :editor="editorRef"
                :defaultConfig="toolbarConfig"
                :mode="mode"
              />
              <Editor
                style=" height: 500px; overflow: hidden"
                v-model="formData[item.prop]"
                :defaultConfig="editorConfig"
                :mode="mode"

                @onCreated="handleCreated"
                @change="handleEditorChange"
              />
            </div>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
    <slot></slot>
    <div class="button" v-if="props.buttonStatus">
      <el-form-item>
        <el-button type="info" @click="handleClose"
          ><span style="font-size: 14px">取消</span></el-button
        >
      </el-form-item>

      <el-form-item>
        <el-button class="save" @click="handleSubmit(0)"
          ><span style="font-size: 14px">保存</span></el-button
        >
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handleSubmit(1)"
          ><span style="font-size: 14px">保存并上架</span></el-button
        >
      </el-form-item>
    </div>
    <div class="button" v-else>
      <el-form-item>
        <el-button type="info" @click="handleClose">取消</el-button>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="handleSubmit()">确认</el-button>
      </el-form-item>
    </div>
  </el-form>
</template>

  <script  setup>
import {
  onMounted,
  defineProps,
  ref,
  defineEmits,
  reactive,
  computed,
  watch,
  onBeforeUnmount,
  shallowRef,
  watchEffect,
  // defineExpose,
} from "vue";
import "@wangeditor/editor/dist/css/style.css"; // 引入 css
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
// import { IToolbarConfig } from '@wangeditor/editor'

const props = defineProps({
  operateForm: { type: Object }, // 用于双向绑定表单数据
  rules: { type: Object }, // 表单验证规则
  operateFormLabel: { type: Object, required: true },
  statusId: { type: Number },
  buttonStatus: { type: Boolean },
  row: Object,
  aaa: Boolean,
  open: Boolean,
});

let thumbnailFile = ref([]);
let videoFile = ref([]);
let imgFile = ref([]);


const dialogVisible3 = ref(false);
const dialogImageUrl = ref();
// const editorKey = ref(0)

const handlePreview = (UploadFile) => {
  dialogImageUrl.value = UploadFile.url;
  dialogVisible3.value = true;
};

const handleRemove = (UploadFile) => {
  emit("handleRemove", UploadFile);
};

// 监听row数据变化并更新文件列表
watchEffect(() => {
  props.statusId;
  if (props.row.thumbnailPath) {
    thumbnailFile.value = [
      { name: "缩略图", url: `${props.row.thumbnailPath}` },
    ];
  }
  if (props.row.videoPath) {
    const data = props.row.videoPath.split(",");
    videoFile.value = data.map((path, index) => ({
      name: `视频${index + 1}`,
      url: `${window.apiConfig.VUE_APP_GATHER_URL.value}/${path}`,
    }));
  }
  if (props.row.productGallery) {
    const data = props.row.productGallery.split(",");
    imgFile.value = data.map((path, index) => ({
      name: `图片${index + 1}`,
      url: `${window.apiConfig.VUE_APP_GATHER_URL.value}/${path}`,
    }));
  }
});

const emit = defineEmits();

const propsData = ref({
  checkStrictly: true,
});

// const formData = reactive(props.operateForm); // 用于存储表单数据

let formData = reactive(); // 用于存储表单数据

watch(
  () => props.operateForm,
  () => {
    formData = props.operateForm;
  },
  {
    immediate: true, // 是否在首次渲染时执行回调
  }
);

const formRef = ref();

const uploader = ref(null);

const imgNumber = ref(null)

const handleChange = (file, fileLists, type) => {
  console.log("fileLists",fileLists.length )
  imgNumber.value = fileLists.length 
  emit("handleChange", file, fileLists, type);
};

const handleRequest = () => {
  emit("handleRequest");
};

// 提交表单数据
const handleSubmit = (status) => {
  formRef.value
    .validate()
    .then(async () => {
      // 点击保存并上架
      if (status === 1) {
        formData.status = 0;
      } else if (status === 0) {
        formData.status = 1;
      } else {
        // console.log("aaa");
      }

      thumbnailFile.value = []; // 如果你使用的是数组来存储文件列表
      videoFile.value = [];
      imgFile.value = [];
      let formDataCopy = JSON.parse(JSON.stringify(formData));
      if (formData.belongColumnId) {
        formDataCopy.belongColumnId = formData.belongColumnId;
      }
      emit("update:modelValue", formDataCopy); // 在表单验证成功后，将最新的表单数据同步回父组件
    })
    .catch((err) => {
      console.error("表单验证失败:", err);
    });
};

const customLinkConfig = {
  prop: "path",
  label: "自定义链接:",
  span: 24,
  width: "50%",
  disabled: false,
  type: "input",
};

const close = () => {
  thumbnailFile.value = [];
  videoFile.value = [];
  imgFile.value = [];
};

// defineExpose({
//   close,
// });

// 取消
const handleClose = () => {
  // 清空文件列表
  close();

  emit("handleClose");
};

// 保存原始的表单标签配置
const originalFormLabel = ref(props.operateFormLabel);

const computedFormLabel = computed(() => {
  let formItems = JSON.parse(JSON.stringify(originalFormLabel.value)); // 创建原始配置的浅拷贝
  switch (formData.columnType) {
    case 0: // 首页
      if (formItems.some((item) => item.prop === "columnTemplate")) {
        formItems.find(
          (item) => item.prop === "columnTemplate"
        ).disabled = true;
        formItems.find((item) => item.prop === "columnTemplate").value = "首页";
      }
      if (formItems.some((item) => item.prop === "targetWindow")) {
        formItems.find((item) => item.prop === "targetWindow").disabled = true;
        formItems.find((item) => item.prop === "targetWindow").value = "本窗口";
      }
      break;
    case 1: // 信息页
      if (formItems.some((item) => item.prop === "targetWindow")) {
        formItems.find((item) => item.prop === "targetWindow").disabled = true;
        formItems.find((item) => item.prop === "targetWindow").value = "本窗口";
      }
      break;
    case 2: // 自定义链接
      formItems = formItems.filter((item) => item.prop !== "columnTemplate");
      // 添加自定义链接输入框配置项
      formItems.push(customLinkConfig);
      break;
    default: // 如果切换回其他状态，则恢复原始配置
      formItems = originalFormLabel.value;
  }

  return formItems;
});

// 监听 formData.columnType 的变化
watch(
  () => formData.columnType,
  () => {
    updateDefaultOptions();
  },
  {
    immediate: true, // 是否在首次渲染时执行回调
  }
);

onMounted(() => {
  updateDefaultOptions();
});

function updateDefaultOptions() {
  switch (formData.columnType) {
    case 0: // 首页
      formData.columnTemplate = 0;
      formData.targetWindow = 0;
      break;
    case 1: // 信息页
      formData.targetWindow = 0;
      break;
    case 2: // 信息页
      formData.targetWindow = 1;
      break;
    // ... 其他情况
    default:
      formData.columnTemplate = "";
      formData.targetWindow = "";
  }
}

// 编辑器实例，必须用 shallowRef
const editorRef = shallowRef();

// 内容 HTML
const token = window.sessionStorage.getItem("token");
const toolbarConfig = {};
const editorConfig = {
  placeholder: "请输入内容...",
  MENU_CONF: {},
};
toolbarConfig.excludeKeys = [
  "insertTable",
  "group-video",
  "codeBlock", // 排除菜单组，写菜单组 key 的值即可
];

editorConfig.MENU_CONF["uploadImage"] = {
  fieldName: "files",
  maxFileSize: 10 * 1024 * 1024, // 10M
  server: `${window.apiConfig.VUE_APP_GATHER_URL.value}api/files/upload-files`,
  allowedFileTypes: [],
  headers: {
    Authorization: `Bearer ${token}`,
  },
  timeout: 5 * 1000, // 5 秒

  customInsert(res, insertFn) {
    // res 即服务端的返回结果
    const url = `${window.apiConfig.VUE_APP_GATHER_URL.value}/${res.data}`;
    // 从 res 中找到 url alt href ，然后插入图片
    insertFn(url);
  },
};

const handleCreated = (editor) => {
 
  editorRef.value = editor; // 记录 editor 实例，重要！
};

// const handleEditorChange = (html) => {
//   const editor = editorRef.value;
//   if (editor) {
//     editor.setHtml(html);
//     formData.content = html;
//   } else {
//     console.warn('编辑器实例未初始化完成，无法更新内容');
//   }
// };

// watch(
//   () => props.open,
//   () => {
//     console.log("aaaaaa",editorRef.value)
//     if(props.open == false ){
//     setTimeout(()=>{
//       const editor = editorRef.value;
//       if (editor == null) return;
//       editor.destroy();
//       console.log("实例被清除",editor.destroy())
//     },1000)
//     }
//   },
//   {
//     immediate: true, // 是否在首次渲染时执行回调
//   }
// );

// 组件销毁时，也及时销毁编辑器
onBeforeUnmount(() => {
  close();
  console.log("aaaaaa", thumbnailFile.value);
  const editor = editorRef.value;
  if (editor == null) return;
  editor.destroy();
});

// watch(
//   () => props.operateForm.content,
//   () => {
//     console.log(typeof props.operateForm.content); // Check the content type
//     if (typeof props.operateForm.content === 'string') {
//       console.log("Setting HTML content:", props.operateForm.content)
//       editorRef.value.setHtml(props.operateForm.content);
//     } else {
//       console.error("Received non-string for HTML content:", props.operateForm.content);
//       editorRef.value.setHtml(""); // Clear the editor
//     }
//   },
//   {
//     immediate: false, // 是否在首次渲染时执行回调
//   }
// );

let expand = ref(false);

const isExpand = () => {
  expand.value = !expand.value;
  const items = document.querySelectorAll(".el-upload-list__item");
  let styleElement = document.createElement("style");
  document.head.appendChild(styleElement);
  let styleSheet = styleElement.sheet;
  items.forEach((item, index) => {
    if (expand.value === true) {
      styleSheet.insertRule(
        `.el-upload-list__item:nth-child(${
          index + 1
        }) {display: inline-flex !important;}`,
        0
      );
    } else {
      if (index > 7) {
        styleSheet.insertRule(
          `.el-upload-list__item:nth-child(${
            index + 1
          }) {display: none !important;}`,
          0
        );
      }
    }
  });
};
</script>
  <style lang="scss"  >
.el-row {
  display: flex;
  flex-direction: column;
}
.el-upload--picture-card {
  width: 100px !important;
  height: 100px !important;
}
.button {
  display: flex;
  justify-content: flex-end;
  .el-form-item {
    margin-left: 20px;
    .el-button {
      width: 100px;
      height: 32px;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      .el-button--info {
        background: #c1cfe5 !important;
      }
      .el-button--primary {
        background: #ffffff !important;
        color: #3185ff;
      }
    }
  }
}
.el-form-item__label {
  width: 100px;
}

.el-upload-list__item {
  display: none !important;
}

.el-upload-list__item:nth-child(1) {
  display: inline-flex !important; /* 只显示前 8 个列表项 */
}
.el-upload-list__item:nth-child(2) {
  display: inline-flex !important; /* 只显示前 8 个列表项 */
}
.el-upload-list__item:nth-child(3) {
  display: inline-flex !important; /* 只显示前 8 个列表项 */
}
.el-upload-list__item:nth-child(4) {
  display: inline-flex !important; /* 只显示前 8 个列表项 */
}
.el-upload-list__item:nth-child(5) {
  display: inline-flex !important; /* 只显示前 8 个列表项 */
}
.el-upload-list__item:nth-child(6) {
  display: inline-flex !important; /* 只显示前 8 个列表项 */
}
.el-upload-list__item:nth-child(7) {
  display: inline-flex !important; /* 只显示前 8 个列表项 */
}
.el-upload-list__item:nth-child(8) {
  display: inline-flex !important; /* 只显示前 8 个列表项 */
}
</style>