import request from '@/utils/request'




  // 上传文件
export const uploadFilesApi = (data) => {
  return request({
    url: `/api/files/upload-files`,
    method: 'POST',
    data: data
  })
}

//新建
export const addContentManageApi = (data) => {
  return request({
    url: `/api/content-manage/center`,
    method: 'POST',
    data: data
  })
}

 // 多条件查询
 export const getContentManageApi = (data) => {
  return request({
    url: `/api/content-manage/date-by`,
    params:data
  })
}

  //编辑栏目
  export const editContentManageApi = (data) => {
    return request({
      url: `/api/content-manage/to-content`,
      method: 'PUT',
      data: data
    })
  }
  

 // 删除栏目
 export const deleteContentManageApi = (data) => {
  return request({
    url: `/api/content-manage/by-id`,
    method: 'DELETE',
    params: {
      id:data
    }
  })
}

  //更变状态
  export const statuManageApi = (data) => {
    return request({
      url: `/api/content-manage/status`,
      method: 'PUT',
      params: data
    })
  }
  

