<template>
  <el-card>
    <div class="common-layout">
      <!-- 用户管理 -->
      <div style="display: flex; flex-direction: row; flex-wrap: nowrap">
        <!-- 登录账号 -->
        <el-form
          ref="formRef1"
          :model="from"
          label-width="auto"
          style="width: 618px; height: 32px; margin-top: 8px"
        >
          <div style="display: flex; flex-direction: row; flex-wrap: nowrap">
            <el-form-item label="登录账号" style="flex: 1; min-width: 30%">
              <el-input
                v-model.trim="datas.params.loginAccount"
                placeholder="请输入"
                style="width: 240px; height: 32px"
              />
            </el-form-item>
            <el-form-item label="状态" style="flex: 1; min-width: 30%">
              <el-select
                v-model.trim="datas.params.status"
                placeholder="请选择"
                clearable
                style="width: 240px; height: 32px"
              >
                <el-option label="启用" :value="0" />
                <el-option label="禁用" :value="1" />
              </el-select>
            </el-form-item>
          </div>
        </el-form>
        <!-- 按钮 -->
        <div class="button_box">
          <el-button class="searchButton" @click="searchButton"
            ><span style="font-size: 14px">查询</span></el-button
          >
          <el-button class="resetButton" @click="resetButton"
            ><span style="font-size: 14px">重置</span></el-button
          >
        </div>
      </div>
      <!-- 新建 -->
      <div class="content_button">
        <el-button plain @click="addData"
          ><span style="font-size: 14px">新建</span>
        </el-button>
      </div>
      <el-dialog
        v-model="dialogFormVisible"
        :title="dialogTitle"
        @close="handleClose"
        style="
          width: 360px;
          height: 340px;
          background: #ffffff;
          box-shadow: 0px 6px 8px 0px rgba(0, 0, 0, 0.12);
          border-radius: 6px 6px 6px 6px;
          border: 1px solid #c5d0e0;
        "
      >
        <el-form
          ref="formRef"
          :rules="rules"
          :model="form1"
          size="mini"
          style="width: 312px; height: 32px; margin-top: 56px"
        >
          <el-form-item
            label="用户名"
            prop="userName"
            :label-width="formLabelWidth"
            style="height: 32px"
          >
            <el-input
              :disabled="modalType !== 0 && modalType !== 1"
              v-model="form1.userName"
              autocomplete="off"
              style="width: 240px; height: 32px"
            />
          </el-form-item>
          <el-form-item
            label="登录账号"
            prop="loginAccount"
            :label-width="formLabelWidth"
            style="height: 32px"
          >
            <el-input
              :disabled="modalType !== 0 && modalType !== 1"
              v-model="form1.loginAccount"
              autocomplete="off"
              style="width: 240px; height: 32px"
            />
          </el-form-item>
          <!--   v-if="modalType === 0 || modalType === 1" -->
          <el-form-item
            label="登录密码"
            prop="password"
            :label-width="formLabelWidth"
            v-if="modalType === 0"
            style="height: 32px"
          >
            <el-input
              :disabled="modalType === 1"
              v-model="form1.password"
              type="password"
              autocomplete="off"
              style="width: 240px; height: 32px"
            />
          </el-form-item>
          <el-form-item
            label="新登录密码"
            prop="password"
            :label-width="formLabelWidth"
            v-if="modalType !== 0 && modalType !== 1"
            style="height: 32px"
          >
            <el-input
              v-model="form1.password"
              type="password"
              autocomplete="off"
              style="width: 240px; height: 32px"
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <div class="dialog-footer">
            <el-button class="cancelBtn" @click="cancel"
              ><span style="font-size: 14px">取消</span></el-button
            >
            <el-button class="certainBtn" @click="addToTable">
              <span style="font-size: 14px">确定</span>
            </el-button>
          </div>
        </template>
      </el-dialog>
      <!-- 表格 -->
      <div class="logManangeTable">
        <elmTable
          :table-data="tableData"
          :table-cols="tableCols"
          :total="totalData"
          :sortStuat="sortStuat"
          :paginationStatu="paginationStatu"
          v-loading="loading"
          @onCurrentPageChange="onCurrentPageChange"
          @onPageSizeChange="onPageSizeChange"
        />
      </div>
    </div>
  </el-card>
</template>

<script setup>
import elmTable from "@/components/elmTableUser.vue";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  // getUserManagementApi, // 用户数据展示
  getByLoginAccountAndStatusApi, // 登录账号和状态查询
  changeStatusApi, // 启用 禁用
  addUserManageApi, // 添加
  editUserManageApi, // 编辑
  deleteUserManageApi, // 删除
  updatePasswordApi, // 修改密码
} from "@/api/userManagementApi";
import { reactive, ref, onMounted } from "vue";

// 表格加载动画
const loading = ref(false);

const paginationStatu = ref(true);

const datas = reactive({
  params: {
    loginAccount: "", // 登录账号
    status: null, // 状态
    pageindex: 1,
    pagesize: 10,
  },
});

// 设置每个表单元素的宽度
const formLabelWidth = ref("100px");

const formRef1 = ref();

const searchButton = async () => {
  getList();
};

// 查询信息 重置
const resetButton = () => {
  datas.params.loginAccount = "";
  datas.params.status = null;
  getList();
};

// 表格数据
const tableData = ref([]);
// 序号列是否启用
const sortStuat = ref(true);
// 表格列
const tableCols = ref([
  {
    label: "用户名",
    prop: "userName",
    align: "center",
    width: "210",
  },
  {
    label: "登录账号",
    prop: "loginAccount",
    align: "center",
    width: "230",
  },
  {
    nameStatus: false,
    label: "状态",
    prop: "status",
    align: "center",
    width: "150",
  },
  {
    label: "创建人",
    prop: "createUser",
    align: "center",
    width: "210",
  },
  {
    label: "创建日期",
    prop: "createDate",
    align: "center",
    width: "230",
  },
  {
    label: "修改人",
    prop: "updateUser",
    align: "center",
    width: "210",
  },
  {
    label: "操作",
    type: "Button",
    width: "280",
    align: "center",
    btnList: [
      {
        type: "primary",
        size: "",
        label: "启用",
        label2: "禁用",
        width: "23%",
        handle: (row) => {
          usage(row);
        },
      },
      {
        type: "primary",
        size: "",
        width: "25%",
        // width: "70",
        label: "编辑",
        label2: "编辑",
        handle: (row) => {
          editData(row);
        },
      },
      {
        type: "primary",
        size: "",
        // width: "40",
        width: "25%",
        label: "修改密码",
        label2: "修改密码",
        handle: (row) => {
          updatePassword(row);
        },
      },
      {
        type: "primary",
        size: "",
        // width: "60",
        width: "27%",
        label: "删除",
        label2: "删除",
        handle: (row) => {
          deleteData(row);
        },
      },
    ],
  },
]);

// 表格数据总量
const totalData = ref(null);

// 分页数据

// 翻页函数
const onCurrentPageChange = (newPage) => {
  datas.params.pageindex = newPage;
  getList();
};
// 选定页面数据量
const onPageSizeChange = (newPageSize) => {
  datas.params.pageindex = newPageSize;
  getList();
};

// const startTime = ref();
// const endTime = ref();
// let completeTime = ref();

//获取表格数据
const getList = async () => {
  // startTime.value = performance.now(); // 开始时间
  loading.value = true;
  const { data } = await getByLoginAccountAndStatusApi(datas.params);
  totalData.value = data.totalCount;
  tableData.value = data.items;
  loading.value = false;
  // endTime.value = performance.now(); // 结束时间

  // completeTime.value = endTime.value - startTime.value;

  // console.log(completeTime.value, "completeTime");

  // if (completeTime.value >= 500) {
  //   loading.value = true; // 如果所需时间大于等于500毫秒，则显示loading
  //   let loadingTime = setInterval(() => {
  //     loading.value = false;
  //     clearInterval(loadingTime);
  //   }, 500);
  //   // console.log(11111111);
  // } else {
  //   loading.value = false; // 如果所需时间小于500毫秒，则不显示loading
  //   // console.log(22222222);
  // }
};

//初始化表格
onMounted(() => {
  getList();
});

// 新建按钮 弹框
const dialogFormVisible = ref(false);
// 弹框title
const dialogTitle = ref("新建");

// 表单输入规则
const rules = reactive({
  userName: [{ required: true, message: "请输入用户名", trigger: "blur" }],
  loginAccount: [
    { required: true, message: "请输入登录账号", trigger: "blur" },
  ],
  password: [{ required: true, message: "请输入登录密码", trigger: "blur" }],
});
// 弹框表单输入
let form1 = reactive({
  id: 0,
  userName: "", // 用户名
  loginAccount: "", // 登录账号
  password: "", // 登录密码
});

// 添加 用户管理数据
const addData = () => {
  dialogFormVisible.value = true;
  modalType.value = 0;
  dialogTitle.value = "新建";
};

//添加弹框 中确认按钮
const formRef = ref();

let modalType = ref(0); // 0是新增 1是编辑 2是修改密码

// 弹框 确认按钮
function addToTable() {
  formRef.value
    // 校验
    .validate()
    .then(async () => {
      // 接收表单的数据 转为json数据
      const paramData = JSON.stringify(form1);
      if (modalType.value === 0) {
        // 添加
        await addUserManageApi(paramData);
        ElMessage({
          type: "success",
          message: "添加成功",
        });
      } else if (modalType.value === 1) {
        // 编辑
        await editUserManageApi(paramData);
        ElMessage({
          type: "success",
          message: "编辑成功",
        });
      } else {
        // 修改密码
        await updatePasswordApi(paramData);
        ElMessage({
          type: "success",
          message: "修改成功",
        });
      }
      // 新增后清空表单 并关闭弹框
      form1 = reactive({
        id: 0,
        userName: "", // 用户名
        loginAccount: "", // 登录账号
        password: "", // 登录密码
      });
      // 清除 校验结果
      formRef.value.clearValidate();
      // 清空表单数据
      formRef.value.resetFields();
      dialogFormVisible.value = false;
      //重新获得表格数据
      getList();
    })
    .catch((err) => {
      console.error("表单验证失败:", err);
    });
}

// 弹窗 右上角取消按钮
const handleClose = () => {
  form1 = reactive({
    id: 0,
    userName: "", // 用户名
    loginAccount: "", // 登录账号
    password: "", // 登录密码
  });
  // 清除 校验结果
  formRef.value.clearValidate();
  // 清空表单数据
  formRef.value.resetFields();
};

// 弹框中 取消按钮
const cancel = () => {
  // 清空表单并关闭弹框
  form1 = reactive({
    id: 0,
    userName: "", // 用户名
    loginAccount: "", // 登录账号
    password: "", // 登录密码
  });
  // 清除 校验结果
  formRef.value.clearValidate();
  // 清空表单数据
  formRef.value.resetFields();
  dialogFormVisible.value = false;
};

// 启用 禁用
const usage = async (row) => {
  let { id, status } = row;
  if (status === 0) {
    status = 1;
    ElMessage({
      message: "禁用成功",
      type: "success",
    });
  } else {
    status = 0;
    ElMessage({
      message: "启用成功",
      type: "success",
    });
  }
  const list = { id: id, status: status };
  await changeStatusApi(JSON.stringify(list));
  getList();
};

// 编辑 用户数据
const editData = (row) => {
  // 将表格中的数据回显到表单上
  for (const key in row) {
    if (Object.prototype.hasOwnProperty.call(form1, key)) {
      if (key !== "password") {
        form1[key] = row[key];
      }
    }
  }
  modalType.value = 1;
  dialogTitle.value = "编辑";
  dialogFormVisible.value = true;
};

// 修改密码
const updatePassword = (row) => {
  dialogFormVisible.value = true;
  dialogTitle.value = "修改密码";
  // 将表格中的数据回显到表单上
  for (const key in row) {
    if (Object.prototype.hasOwnProperty.call(form1, key)) {
      form1[key] = row[key];
    }
  }
  modalType.value = 2;
};

// 删除 用户信息 （消息弹出框）
const deleteData = (row) => {
  ElMessageBox.confirm("是否确认删除？", "删除", {
    confirmButtonText: "确认",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(async () => {
      ElMessage({
        type: "success",
        message: "删除成功",
      });
      const { id } = row;
      await deleteUserManageApi(id);
      getList();
    })
    .catch(() => {
      ElMessage({
        type: "info",
        message: "取消删除",
      });
    });
};
</script>

<style lang="scss" scoped >
* {
  margin: 0;
  padding: 0;
}

.common-layout {
  // background: #ffffff;
}
.el-card {
  width: 99%;
  height: 95%;
  background: #ffffff;
}

//  弹框标头
::v-deep .el-dialog__header {
  width: 326px;
  height: 40px; // 50
  position: absolute;
  top: 0px;
  left: 0px;
  border-radius: 6px 6px 0px 0px;
  background: #f0f4fa;
}
::v-deep .el-dialog__title {
  font-weight: 400;
  font-size: 16px;
  color: #03101f;
  line-height: 19px;
  text-align: left;
  font-style: normal;
  margin-left: 15px;
  position: absolute;
  top: 14px;
  // 14 15
}

::v-deep .el-icon svg {
  width: 1em;
  height: 1em;
  color: #9cb0cc;
  // 17
}

.button_box {
  width: 208px;
  height: 32px;
  margin-top: 8px;
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // background: olive;
  .searchButton {
    width: 100px;
    height: 32px;
    font-weight: 400;
    color: #ffffff;
    line-height: 16px;
    font-style: normal;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #3185ff;
    background: #3185ff;
    text-align: right;
  }
  .searchButton:hover {
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    font-style: normal;
    background: #3185ff;
    border-radius: 4px 4px 4px 4px;
  }
  .searchButton:focus {
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    font-style: normal;
    background: #3185ff;
    border-radius: 4px 4px 4px 4px;
  }
  .resetButton {
    width: 100px;
    height: 32px;
    font-weight: 400;
    font-size: 14px;
    color: #3185ff;
    line-height: 16px;
    font-style: normal;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #3185ff;
  }
  .resetButton:hover {
    font-weight: 400;
    font-size: 14px;
    color: #3185ff;
    line-height: 16px;
    font-style: normal;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #3185ff;
    background: #ffffff;
  }
  .resetButton:focus {
    font-weight: 400;
    font-size: 14px;
    color: #3185ff;
    line-height: 16px;
    font-style: normal;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #3185ff;
    background: #ffffff;
  }
}

// 星号
.required::before {
  content: "*";
  color: red;
}

// 新建
.content_button {
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
  margin-bottom: 14px;
  // background: wheat; margin: 10px 10px
  .el-button {
    width: 100px;
    height: 32px;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 16px;
    font-style: normal;
    background: #3185ff;
    border-radius: 4px 4px 4px 4px;
  }
  .el-button:hover {
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 16px;
    font-style: normal;
    background: #3185ff;
    border-radius: 4px 4px 4px 4px;
  }
  .el-button:focus {
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 16px;
    font-style: normal;
    background: #3185ff;
    border-radius: 4px 4px 4px 4px;
  }
  // 设置
  .set_bac {
    width: 80px;
    height: 50px;
    // background: olivedrab;
  }
}
// 弹框按钮
.dialog-footer {
  width: 212px;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 104px;
  position: absolute;
  bottom: 32px;
  // background: wheat;
  .cancelBtn {
    width: 100px;
    height: 32px;
    font-weight: 400;
    font-size: 14px;
    color: #515d6b;
    line-height: 16px;
    font-style: normal;
    background: #c1cfe5;
    border-radius: 4px 4px 4px 4px;
  }
  .cancelBtn:hover {
    font-weight: 400;
    font-size: 14px;
    color: #515d6b;
    line-height: 16px;
    font-style: normal;
    background: #c1cfe5;
    border-radius: 4px 4px 4px 4px;
  }
  .certainBtn {
    width: 100px;
    height: 32px;
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 16px;
    font-style: normal;
    background: #3185ff;
    border-radius: 4px 4px 4px 4px;
  }

  .certainBtn:hover {
    font-weight: 400;
    font-size: 14px;
    color: #ffffff;
    line-height: 16px;
    font-style: normal;
    background: #3185ff;
    border-radius: 4px 4px 4px 4px;
  }
}
// 表格
.logManangeTable {
  // width: 1584px;
  width: 100%;
  height: 645px;
  // margin-right: 24px;
  // background: palegoldenrod;
}
</style>