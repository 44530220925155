
import Layout from '@/views/managementSystem/layout/index.vue';
import siteAdministration from '@/views/managementSystem/siteAdministration';
import columnManagement from '@/views/managementSystem/columnManagement';
import userManagement from '@/views/managementSystem/userManagement';
import logManagement from '@/views/managementSystem/logManagement';
import newsCenter from '@/views/managementSystem/contentManagement/newsCenter';
import jobCenter from '@/views/managementSystem/contentManagement/jobCenter';
import productCenter from '@/views/managementSystem/contentManagement/productCenter';
import bannerManagement from '@/views/managementSystem/contentManagement/bannerManagement';
import contentIntroduction from '@/views/managementSystem/contentManagement/contentIntroduction';
import login from '@/views/login';



const routes = [
    { 
      path: '/management', 
      component: Layout ,
      name:"management",
      redirect:"/management/siteAdministration",
      meta: { isBackend: true }, // 添加 meta 属性
      children:[
        // 站点管理
        {
          path:"siteAdministration",
          name:"siteAdministration",
          component:  siteAdministration,
          meta: { isBackend: true }, // 添加 meta 属性
        },
        //  栏目管理
        {
          path:"columnManagement",
          component:  columnManagement,
          meta: { isBackend: true }, // 添加 meta 属性
        },
        //新闻中心 
        {
          path:"newsCenter",
          component: newsCenter,
          meta: { isBackend: true }, // 添加 meta 属性
        },
         //产品中心
        {
          path:"productCenter",
          component: productCenter,
          meta: { isBackend: true }, // 添加 meta 属性
        },
         //岗位中心 
         {
          path:"jobCenter",
          component: jobCenter,
          meta: { isBackend: true }, // 添加 meta 属性
        },
         //内容介绍
         {
          path:"contentIntroduction",
          component: contentIntroduction,
          meta: { isBackend: true }, // 添加 meta 属性
        },
         //Banner管理
         {
          path:"bannerManagement",
          component: bannerManagement,
          meta: { isBackend: true }, // 添加 meta 属性
        },
        {
          path:"columnManagement",
          component:  columnManagement,
          meta: { isBackend: true }, // 添加 meta 属性
        },
        // 用户管理
        {
          path:"userManagement",
          component:  userManagement,
          meta: { isBackend: true }, // 添加 meta 属性
        },
        // 日志管理
        {
          path:"logManagement",
          component: logManagement,
          meta: { isBackend: true }, // 添加 meta 属性
        },
      ]
    },
    {
      path: '/login', 
      component: login ,
      name:"login",
      meta: { isBackend: true }
    },
  ];
  

  
  export default routes