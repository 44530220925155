import request from "@/utils/request";

// 分页查询 用户
export const getUserManagementApi = (data) => {
  return request({
    url: `/api/user-basic/user-by-page`,
    method: "GET",
    params: data,
  });
};

// 启用 禁用
export const changeStatusApi = (data) => {
  return request({
    url: `/api/user-basic/user-basic`,
    method: "PUT",
    data: data,
  });
};

// 根据登录账号和状态查询
export const getByLoginAccountAndStatusApi = (data) => {
  return request({
    url: `/api/user-basic/user-basic-by-la-or-status`,
    method: "GET",
    params: data,
  });
  // return request({
  //   url: `/api/user-basic/users-by-la-or-status/?pageindex=${data.params.pageindex}&pagesize=${data.params.pagesize}`,
  //   method: "GET",
  //   params: JSON.stringify(data.form),
  // });
};

// 添加 用户数据
export const addUserManageApi = (data) => {
  return request({
    url: `/api/user-basic/user-basic`,
    method: "POST",
    data: data,
  });
};

// 编辑 用户数据
export const editUserManageApi = (data) => {
  return request({
    url: `/api/user-basic/user-basic`,
    method: "PUT",
    data: data,
  });
};

// 修改密码
export const updatePasswordApi = (data) => {
  return request({
    url: `/api/user-basic/password`,
    method: "PUT",
    data: data,
  });
};

// 删除 用户数据
export const deleteUserManageApi = (data) => {
  return request({
    url: `/api/user-basic/user-basic`,
    method: "DELETE",
    params: {
      id: data,
    },
  });
};
