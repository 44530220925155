import request from '@/utils/request'

//  获取 全部父子栏目
export const getAllColumnApi = (data) =>{
  return request({
    url: `/api/column-manage/all-columns`,
    method: 'GET',
    params: data
  })
}

// 分页查询栏目
export const getcolumnManageApi = (data) => {
    return request({
      url: `/api/column-manage/all-column`,
      method: 'GET',
      params: data
    })
  }

//新建栏目
  export const addcolumnManageApi = (data) => {
    return request({
      url: `/api/column-manage/save`,
      method: 'POST',
      data: data
    })
  }
  
 

  //编辑栏目
  export const editcolumnManageApi = (data) => {
    return request({
      url: `/api/column-manage/column`,
      method: 'PUT',
      data: data
    })
  }
  
  // 删除栏目
  export const deletecolumnManageApi = (data) => {
    return request({
      url: `/api/column-manage/column-by-id`,
      method: 'DELETE',
      params: {
        id:data
      }
    })
  }

  // 查找子栏目
  export const getAllSubColumnsApi = (data) => {
    return request({
      url: `/api/column-manage/all-sub-columns`,
      method: 'GET',
      params: data
    })
  }

  // 根据id查询栏目
  export const getSelectByIdApi = (data) => {
    return request({
      url: `/api/column-manage/select-by-id`,
      method: 'GET',
      params: data
    })
  }

  // 获取所有栏目
  export const getAllColumnsApi = (data) => {
    return request({
      url: `/api/column-manage/all-columns`,
      method: 'GET',
      params: data
    })
  }