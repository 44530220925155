<template>
  <div class="tag">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item v-for="item of tagData" :key="item.name">{{
        item.name
      }}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script  setup>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";

// 获取 store 实例
const store = useStore();

const tagData = computed(() => {
  if (store.state.tagData.length > 1 || localStorage.getItem("tagData")) {
    return JSON.parse(localStorage.getItem("tagData"));
  } else {
    return store.state.tagData;
  }
});

onMounted(() => {
  tagData.value = store.state.tagData;
});
</script>

<style lang="scss"  >
.tag {
  margin: 10px 0;
  .el-breadcrumb__item .el-breadcrumb__inner {
    font-size: 14px;
    color: #333333;
    font-weight: 400;
    line-height: 19px;
  }
  .el-breadcrumb__item:first-child .el-breadcrumb__inner {
    font-size: 16px;
    color: #333333;
    font-weight: 600;
    line-height: 19px;
  }
}
</style>