<template>
  <section class="ces-table-page">
    <section class="ces-table">
      <el-table
        :height="props.height"
        row-key="id"
        :data="formattedTableData"
        lazy
        style="width: 100%"
        v-loading="loading"
        :load="load"
        :tree-props="{ hasChildren: 'hasChildren' }"
        align="center"
      >
        <el-table-column
          v-if="props.sortStuat"
          type="index"
          label="序号"
          width="60"
          align="center"
        ></el-table-column>
        <el-table-column
          v-for="item in props.tableCols"
          :key="item.id"
          :prop="item.prop"
          :label="item.label"
          :width="item.width"
          :align="item.align"
          :show-overflow-tooltip="{
            trigger: 'hover',
            placement: 'top',
            showAfter: 600,
          }"
        >
          <template #default="scope">
            <span v-if="item.type === 'Button'" class="button">
              <!-- 新增对节点层级的判断 -->
              <template v-if="scope.row.parentId === 0 || !scope.row.parentId">
                <!-- 假设父节点的level为'parent' -->
                <span
                  v-for="(btn, index) in item.btnList"
                  :key="index"
                  class="btnList"
                >
                  <el-button
                    link
                    :style="{ width: btn.width ? btn.width + 'px' : '32px' }"
                    :type="btn.type"
                    @click.stop="btn.handle(scope.row)"
                    :disabled="scope.row[btn.disabled]"
                  >
                    {{
                      scope.row.status
                        ? scope.row.status === 0
                          ? btn.label
                          : btn.label2
                        : btn.label
                    }}
                    <!-- 如果btn有parentLabel属性，则显示parentLabel，否则显示label -->
                  </el-button>
                </span>
              </template>
              <template v-else>
                <!-- 假设子节点的level为'child' -->
                <span
                  v-for="(btn, index) in item.btnList"
                  :key="index"
                  class="btnList"
                >
                  <el-button
                    v-if="!btn.childHide"
                    link
                    :style="{ width: btn.width ? btn.width + 'px' : '32px' }"
                    :type="btn.type"
                    @click.stop="btn.handle(scope.row)"
                    :disabled="scope.row[btn.disabled]"
                  >
                    {{ btn.childLabel || btn.label }}
                    <!-- 如果btn有childLabel属性，则显示childLabel，否则显示label -->
                  </el-button>
                </span>
              </template>
            </span>
            <!-- 图像 -->
            <el-image
              v-if="item.type === 'Image'"
              preview-teleported="true"
              style="width: 100px"
              :src="scope.row[item.prop]"
              :preview-src-list="[scope.row[item.prop]]"
              @click="item.handle && item.handle(scope.row)"
            >
            </el-image>
            <!-- 默认 -->
            <span v-if="!item.type" class="overflow_tooltip">
              <div
                v-if="
                  scope.row[item.prop] === false ||
                  (scope.row[item.prop] != '' &&
                    scope.row[item.prop] != null) ||
                  (scope.row[item.prop] === 0 && scope.row[item.prop] != 'null')
                "
              >
                {{
                  item.prop === "columnType"
                    ? scope.row[item.prop] === 0
                      ? "首页"
                      : scope.row[item.prop] === 1
                      ? "信息页"
                      : scope.row[item.prop] === 2
                      ? "自定义链接"
                      : null
                    : item.prop === "status"
                    ? scope.row[item.prop] === 0
                      ? "启用"
                      : scope.row[item.prop] === 1
                      ? "禁用"
                      : null
                    : item.prop === "isDisplay" || item.prop === "isShow"
                    ? scope.row[item.prop]
                      ? "是"
                      : "否"
                    : scope.row[item.prop]
                }}
              </div>
              <div v-else>--</div>
            </span>
          </template>
        </el-table-column>
      </el-table>
    </section>
    <div class="paginationUser" v-if="props.paginationStatu">
      <el-pagination
        :current-page="currentPage4"
        :page-size="pageSize4"
        :page-sizes="[5, 10, 20, 30]"
        jumper-text="'到第' + (props.total || 0) + '页'"
        small="small"
        layout=" prev, pager, next, sizes, jumper"
        :total="props.total"
        @update:current-page="onCurrentPageChange"
        @update:page-size="onPageSizeChange"
      />
    </div>
  </section>
</template>
<script setup>
import { defineProps, defineEmits, computed, ref } from "vue";

const currentPage4 = ref(1);
const pageSize4 = ref(10);
const props = defineProps({
  tableData: { type: Array, default: () => [] },
  tableCols: { type: Array, default: () => [] },
  total: { type: Number },
  sortStuat: { type: Boolean },
  height: { type: Number },
  paginationStatu: { type: Boolean },
});
const emit = defineEmits();

// 创建一个新的响应式数据属性来保存处理过的 tableData
const formattedTableData = computed(() => {
  return props.tableData.map((item) => ({
    ...item,
    thumbnailPath: `${window.apiConfig.VUE_APP_GATHER_URL.value}/${item.thumbnailPath}`,
  }));
});

function onCurrentPageChange(newPage) {
  currentPage4.value = newPage;
  emit("onCurrentPageChange", currentPage4.value);
}

function onPageSizeChange(newPageSize) {
  pageSize4.value = newPageSize;
  emit("onPageSizeChange", pageSize4.value);
}
function load(row, treeNode, resolve) {
  emit("onLoad", row, treeNode, resolve);
}
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}
.btnList {
  .el-button {
    span {
      border-bottom: 1px solid #3185ff;
    }
  }
}
.ces-table-page {
  width: 100%;
  height: 100%;
  position: relative;
}
.paginationUser {
  position: absolute;
  top: 83%;
  right: 5%;
  .el-input {
    width: 40px !important;
    height: 28px !important;
    border-radius: 4px 4px 4px 4px;
  }
}
</style>
<style lang="scss" scoped>
::v-deep.ces-table-page {
  .cell.el-tooltip {
    display: flex;
    // justify-content: center;
    align-items: center;
  }
  .el-table__row.el-table__row--level-1 {
    background-color: rgb(241, 241, 241);
  }
}
</style>
