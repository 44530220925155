import axios from "axios";
import { ElMessage } from "element-plus";

const service = axios.create({
  baseURL: window.apiConfig.VUE_APP_GATHER_URL.value,
  timeout: 5000,
});

service.interceptors.request.use(
  (config) => {
    const token = window.sessionStorage.getItem("token");
    // 根据条件设置不同的 Content-Type
    if (shouldUseMultipartFormData(config.url, config.data)) {
      config.headers = {
        "Content-Type": "multipart/form-data",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
    } else {
      config.headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(new Error(error));
  }
);

service.interceptors.response.use(
  (response) => {
    if (response.status === 200 || response.status === 201) {
      return response.data;
    } else {
      ElMessage.error("请求失败");
      return Promise.reject(new Error("请求失败"));
    }
  },
  (error) => {
    error.response && ElMessage.error(error.response.data);
    return Promise.reject(new Error(error.response.data));
  }
);

// 定义一个辅助函数判断是否需要使用 multipart/form-data
function shouldUseMultipartFormData(url) {
  // 示例：若接口以 '/upload' 结尾则使用 multipart/form-data
  return url.endsWith("/upload-files");
}
export default service;
