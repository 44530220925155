import request from "@/utils/request";

// 分页查询 日志管理
export const getLogManagementApi = (data) => {
  return request({
    url: `/api/record-basic/record-list`,
    method: "GET",
    params: data,
  });
};

// 根据登陆账号模糊查询
export const getByLoginAccountApi = (data) => {
  return request({
    url: `/api/record-basic/record-list-by-login-account`,
    method: "GET",
    params: data,
    // url: `/api/record-basic/record-list-by-login-account/?pageindex=${data.params.pageindex}&pagesize=${data.params.pagesize}`,
    // method: "POST",
    // data: JSON.stringify(data.form),
  });
};
