<template>
  <el-card class="common-layout">
    <div class="bac">
      <div class="formBac">
        <div class="title">官网管理系统</div>
        <el-form
          ref="formRef"
          style="max-width: 400px"
          :model="ruleForm"
          status-icon
          :rules="rules"
          label-width="auto"
          class="demo-ruleForm"
        >
          <el-form-item label="用户名" prop="loginAccount">
            <el-input
              v-model="ruleForm.loginAccount"
              type="text"
              autocomplete="off"
              placeholder="请输入登入账号"
            />
          </el-form-item>
          <el-form-item label="密码" prop="password" style="margin-left: -14px">
            <el-input
              v-model="ruleForm.password"
              type="password"
              autocomplete="off"
              placeholder="请输入密码"
              style="margin-left: 14px"
            />
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="submitForm(ruleFormRef)">
              登录
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-card>
</template>

<script setup>
import { reactive, ref } from "vue";
import { getLoginApi } from "@/api/login.js";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { useStore } from "vuex";

// 获取 store 实例
const store = useStore();

const ruleForm = reactive({
  loginAccount: "",
  password: "",
});

const rules = reactive({
  loginAccount: { required: true, message: "请输入登入账号", trigger: "blur" },
  password: { required: true, message: "请输入密码", trigger: "blur" },
});

const formRef = ref(null); // 定义表单引用
const $router = useRouter();
async function submitForm() {
  // 触发表单验证
  await formRef.value.validate(async (valid) => {
    if (valid) {
      // 直接提取ruleForm对象中的表单数据
      const formData = {
        loginAccount: ruleForm.loginAccount,
        password: ruleForm.password,
      };

      try {
        // 发送登录请求
        const { data } = await getLoginApi(formData);
        // 根据后端接口返回处理登录结果
        if (data.isSuccess === true) {
          sessionStorage.setItem("token", data.accessToken);
          $router.push("/management");
          store.commit("setUseName", data.userName);
          ElMessage({
            type: "success",
            message: "登入成功",
          });
        } else {
          ElMessage({
            type: "error",
            message: decodeURIComponent(data.message),
          });
        }
      } catch (error) {
        console.error("登录请求出错：", error);
        // 显示错误消息或其他反馈
      }
    }
  });
}
</script>

<style lang="scss" scoped >
* {
  margin: 0;
  padding: 0;
}
.common-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 99.8vh;
  // .el-form-item__label-wrap {
  //   margin-left: -14px;
  // }
  .bac {
    width: 1920px;
    height: 1080px;
    background: url("../../assets/login.png") no-repeat;
    background-size: 100% 100%;
  }
  .formBac {
    width: 644px;
    height: 1080px;
    margin-top: -20px;
    margin-left: 1280px;
    background: #ffffff;
    position: relative;
    .title {
      font-weight: 400;
      font-size: 32px;
      color: #0f253e;
      line-height: 38px;
      letter-spacing: 3px;
      text-align: left;
      font-style: normal;
      margin-left: 216px;
      position: absolute;
      top: 291px;
    }
    .el-form {
      font-weight: 400;
      font-size: 16px;
      color: #999999;
      line-height: 19px;
      text-align: left;
      font-style: normal;
    }
    .demo-ruleForm {
      margin-top: 45px;
      margin-left: 120px;
      position: absolute;
      top: 291px;

      .el-input {
        width: 400px;
        height: 50px;
      }
      .el-button {
        width: 400px;
        height: 50px;
        font-weight: 500;
        font-size: 18px;
        color: #ffffff;
        line-height: 21px;
        letter-spacing: 1px;
        text-align: left;
        font-style: normal;
        background: #3185ff;
        border-radius: 6px 6px 6px 6px;
      }
      .el-form-item {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
</style>