import { createStore } from 'vuex'
import  { getcolumnManageApi }   from '@/api/columnManagementApi'
import { columnPathMapping ,columnTemplateMapping} from "@/store/mapping.js"

// 你的 state、mutations、actions 和 getters
const state = {
  statu:true,
  tagData:[
    {
      name:"网站管理",
      path:"siteAdministration"
    }
  ],
  tag2Data:[
  ],
  useName:null,
  dynamicRoutes:[],

  bannerPath:null
}

const mutations = {
  editStatu(state,statu){
    state.statu = statu
    localStorage.setItem('state',JSON.stringify(statu))
  },

// 改变后台管理系统面包屑状态
  addTagData(state, item) {
    if(item.pathId===0)
    {
      state.tagData = [
        {
          name:"网站管理",
          path:"siteAdministration"
        }
      ]
      state.tagData.push(item)
      localStorage.setItem('tagData',JSON.stringify(state.tagData))
    }else{
      state.tagData = [
        {
          name:"网站管理",
        },
        {
          name:"内容管理",
        }
      ]
      state.tagData.push(item)
      localStorage.setItem('tagData',JSON.stringify(state.tagData))
    }

  },

// 改变前台面包屑状态
  addTag2Data(state, item) {
    if(item.statu !== 1){
      const pathData = {
        name:item.columnName,
      }
      state.tag2Data=[{
        name:"首页",
        path:"HomePage"
      }]
      state.tag2Data.push(pathData)
      localStorage.setItem('tag2Data',JSON.stringify(state.tag2Data))
    }else{
      const pathData = {
        name:item.columnName,
      }
      const pathData2 = {
        name:item.name,
      }
      state.tag2Data=[{
        name:"首页",
        path:"HomePage"
      }]
      state.tag2Data.push(pathData)
      state.tag2Data.push(pathData2)
      localStorage.setItem('tag2Data',JSON.stringify(state.tag2Data))
    }


  },

 // 改变面包屑状态成搜索页
  addTag2Data2(state, item){

    state.tag2Data=[{
      name:"首页",
      path:"HomePage"
    }]
    state.tag2Data.push(item)
    localStorage.setItem('tag2Data',JSON.stringify(state.tag2Data))
  },


// 删除面包屑导航
  deleteTag2Data(state){
    state.tag2Data.pop()
    localStorage.setItem('tag2Data',JSON.stringify(state.tag2Data))
  },

  setUseName(state, item){
    state.useName = item
    localStorage.setItem('useName',JSON.stringify(state.useName))
  },
  setDynamicRoutes(state, routes) {
    state.dynamicRoutes = routes;
  }
}

const actions = {
  async fetchDynamicRoutes({ commit }) {
    try {
      // 替换为实际的API请求
      const params={
        pageindex:1,
        pagesize:100
      }
      const {data} = await getcolumnManageApi(params); // 假设这是一个获取路由数据的API

      const datas = data.items.map((item)=>{
        return{
          columnName:item.columnName,
          id:item.id,
          links:item.path,
          path: columnPathMapping[item.columnPath] || "",
          name: columnPathMapping[item.columnPath],
          component: columnTemplateMapping[item.columnTemplate == -1 ? 10 :item.columnTemplate ],
          targetWindow:item.targetWindow,
          parentId:item.parentId,
          sort:item.sort,
          children: []
        }
      })
      const routes = {}
      // 步骤3: 将子元素挂载到父元素下
      datas.forEach(item => {
        const { id, parentId} = item;
          // 如果是父栏目
          if (parentId === 0) {
            routes[id] = {
              ...item
            };
          }
      });

      datas.forEach(item => {
        const {  parentId} = item;
          if(parentId !== 0) {
            // 如果是子栏目
            if (routes[parentId]) {
              // 找到父栏目并将子栏目添加进去
              routes[parentId].children.push({ ...item});
            }
          }
      });


      sessionStorage.setItem("routes",JSON.stringify(routes) )
      commit('setDynamicRoutes', routes);
    } catch (error) {
      console.error('Failed to fetch dynamic routes:', error);
      // 可能需要在此处处理错误，比如显示错误提示或默认路由
    }
  }
}

const getters = {}

export default createStore({
  state,
  mutations,
  actions,
  getters,
})
